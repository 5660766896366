.m-logo {
  width: 30.3rem;
  margin-left: 1.7rem;
  padding-top:0.8rem;
  flex-grow: 1;
  width: 67%;
  @include desktop {
    flex-grow: 0;
    padding-top: 0;
    margin-left: 2rem;
  }
  .m-logo-img {
    max-width: 100%;
    height: auto;
    max-height: 67px;
    @include desktop {
      margin-left: 0;
    }
  }
}
.m-news {
  background: url('../img/icon-press-release.png') no-repeat top left;
}
.m-events {
  background: url('../img/icon-calendar.png') no-repeat top left;
}

.m-button-chevron,
.m-link-chevron {
  vertical-align: text-top;
  margin-left: 0.7rem;
}

.m-modal-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleX(-1);
  z-index: -1;
}
