.m-quote {
    height: 100%;
    display: flex;
    align-items: center;
    .m-quote-text {
        @include conduit-itc-medium-italic;
        font-size: 2.1rem;
    }
    .m-quote-author_title {
        font-size: 1.4rem;
    }
     
}

.m-quotes {
    display: inline-block;
    vertical-align: middle;
    @include tablet {
        max-width: 90%;
    }
    @include desktop {
        max-width: 75%;
    }
}



.m-quote-author {
    @include conduit-itc-bold;
    text-transform: uppercase;
    display: block;
    font-size: 1.8rem;

} 
.m-quote-author_title {
    @include conduit-itc-medium;
    text-transform: capitalize;
    margin: 0;
}

.m-column-1 {
    @include tablet {
        .m-quote {
            justify-content: flex-end;
        }
        .m-quote-text,
        .m-quote-author,
        .m-quote-author_title {
            text-align: right;
        }
    }
}