


.m-product_block .m-products {
  padding-top: 4rem;
}

.m-product {
  margin: 0 auto;
  padding: 2.5rem 0;
  
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @include tablet {
    width: 100%;
    padding: 4rem 0;
  }

  @include desktop {
    width: 85rem;
    justify-content: space-between;
    border-bottom: 0.1rem solid #d9d9d9;
  }

  &:last-child {
    border-bottom: none;
  }

  p {
    margin-bottom: 2.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -$mobile-content-padding;
    height: 0.1rem;
    width: calc((#{$mobile-content-padding} * 2) + 100%);
    background-color: #d9d9d9;
    @include desktop {
      display: none;
    }
  }
}

// product logo or image
.m-product-image {
  @include flexCenter;
  width: 100%;
  padding: 2rem;
  margin: 2rem 0;

  @include tablet {
    width: 28%;
    padding-right: 3rem;
  }

  @include desktop {
    width: 22rem;
    padding: 0;
  }

  .img-responsive {
    width: 67%;
    height: auto;
    display: block;

    @include tablet {
      margin: 0 auto;
      width: 100%;
      max-width: 100%;
    }
  }
}



// product text and link
.m-product-content {
  width: 100%;
  
  @include tablet {
    width: 72%;
  }

  @include desktop {
    width: 65%;
  }
}

.m-product-links {
  text-align: left;
  .m-button-arrow {
    @include mobile-only {
      margin: 4rem 0;
    }
  }
  a {
    display: block;
    margin-bottom: 1rem;
    text-align: left;
    margin-top: 2rem;
    
    @include tablet {
      text-align: center;
      display: inline;
      margin: 3rem 1rem 0 0;

      &:not(.m-button-arrow) {
        font-size: 1.4rem;
      }
    }
   
  }

  .m-text-link {
    @include mobile-only {
      margin-bottom: 2rem;
    }
  }

  @include tablet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }
}


.about .m-products {
  background-color: #ffffff;

  .m-product-image {
    margin: 0;
  }
}