.m-patient-support {
	@include tablet {
		display: flex;
		justify-content: space-between;
	}
}

.m-patient-support-content{
	h2{
		margin-bottom: 2.5rem;
	}

	a.m-button-arrow{
		margin-top: 1.5rem;
	}

	@include tablet{
		padding-right: 4rem;
	}
}

.m-patient-support-image{
	margin-top: 2.5rem;
	display: flex;
	align-items: flex-end;

	@include tablet {
		margin-top: 0;
	}
}