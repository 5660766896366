// PRESS RELEASE TABLE LIST
//----------------------------
.m-press-releases {
  background: $background-colour;
  padding: 4rem 0;
}

.m-press-release-date {
  @include conduit-itc-medium;
  font-size: 1.9rem;
  margin-bottom: 3rem;
}

.m-press-release-count {
  font-weight: bold;
  font-size: 2rem;
  padding: 2rem 0;
}

.m-press-release-filter {
  @include arial();
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 4rem;
  padding-bottom: 4rem;

  @include tablet {
    flex-direction: row;
  }
  @include desktop {
    max-width: $desktop-content-width;
  }
}
.m-press-release-label {
  font-weight: bold;
  font-size: 1.6rem;
  margin-right: 1rem;
  align-self: flex-start;
  margin-bottom: 1rem;
  @include tablet {
    margin-bottom: 0;
    align-self: auto;
  }
}

.m-press-search-text {
  flex-grow: 1;
  border: 0;
  background: #ebebeb;
  height: 3.2rem;
  padding: 0.5rem 1.5rem;
  font-size: 1.2rem;
  width: 100%;
  @include tablet {
    width: auto;
  }
}

.m-submit-button {
  @include conduit-itc-medium;
  width: 16.5rem;
  cursor: pointer;
  position: relative;
  background: $brand-colour-3;
  color: $white;
  text-align: left;
  margin-left: 1rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  padding: 0 1rem;
  height: 3.2rem;
  line-height: 3.2rem;
  border: 0;
  transition: all 0.3s ease;
  align-self: flex-end;
  margin-top: 1rem;
  @include tablet {
    margin-top: 0;
    align-self: auto;
  }
  &::after {
    @include verticalCentre;
    content: "";
    background: url("../img/white-chevron.png") no-repeat;
    background-size: cover;
    width: 0.9rem;
    height: 1.6rem;
    right: 1rem;
  }

  &:hover,
  &.hover,
  &:focus {
    background: $brand-colour-1;
  }
}

.m-press-release-table {
  position: relative;
  margin: 0 $mobile-content-padding;
  font-size: 1.6rem;
  line-height: 2.3rem;
  padding: 2rem 0 4rem;
  border: 0.1rem solid #aaaaaa;
  @include tablet-only {
    width: 95%;
  }
  @include desktop {
    margin: 0 auto 0;
    width: 100%;
    max-width: $desktop-content-width;
  }
}

.m-press-release-header {
  @include conduit-itc-medium;
  font-size: 1.8rem;
  background: $brand-colour-3 url(../img/pressreleasesorter.png) no-repeat right center;
  // needs to be seperate for IE support
  background-position-x: calc(100% - 1rem);
  color: $white;
  padding: 0.5rem 0;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  &:first-child {
    background-image: none;
  }
  &:not(:last-child) {
    border-right: 0.1rem solid #aaaaaa;
  }
  &.ascending,
  &.descending {
    background-color: $brand-colour-1;
  }

  &.ascending {
    background-position-y: -12.1rem;
  }
  &.descending {
    background-position-y: 1.9rem;
  }
}

.m-press-release-link {
  color: $dark-grey;
  transition: all 0.3s ease;
  &:hover,
  &.hover,
  &:focus {
    color: $brand-colour-1;
  }
}

th,
td {
  vertical-align: top;
  &.is-view {
    width: 4.5rem;
  }

  &.is-date {
    width: 11.5rem;
  }
}

.m-press-release-td {
  background: #dfdbda;
  padding: 1rem;
  text-align: left;
  border-bottom: 0;
  &:not(:last-child) {
    border-right: 0.1rem solid #aaaaaa;
  }

  &.is-date,
  &.is-view {
    text-align: center;
  }

  &.releasetitle {
    word-break: break-word;
  }

  .is-expanded &,
  &.is-sorted-by {
    background: $white;
  }
}

.m-view-button {
  cursor: pointer;
  display: block;
  width: 2.5rem;
  height: 2.3rem;
  background: url("../img/icon-arrows.png") no-repeat 0.8rem -1.8rem #ecac00;
  transition-delay: 800ms;
  text-indent: -9999.9rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $brand-colour-1;
  }

  &.is-expanded {
    transform: rotate(180deg);
    background-color: $brand-colour-1;
  }
}

.m-press-release-expandable {
  border-bottom: 0.1rem solid #aaaaaa;
  padding: 0;
  .rich-text {
    padding-left: 7rem;
    padding-right: 2rem;
    background: $white;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

    &.is-expanded {
      max-height: 100rem;
      transition: max-height 1s ease-in-out;
      border-top: 0.1rem solid #aaaaaa;
    }
  }
}

.m-press-release-data {
  width: 100%;
}

// PRESS RELEASE ARTICLE
//----------------------------
.m-press-release-article {
  background: $white;
  padding: $gutter;
  h2 {
    font-size: 3rem;
    &:not(:first-of-type) {
      @include arial;
      margin: $gutter 0 0;
    }
  }

  h2 + h3 {
    margin-bottom: 0;
    font-weight: bold;
  }

  h3 {
    margin-top: $gutter;
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 1.6rem;
  }

  h3,
  h4 {
    @include arial;
    color: $body-copy;
  }
  h3,
  p {
    margin-bottom: 2rem;
  }

  p {
    color: $body-copy;
  }

  h4 {
    margin-bottom: 0;
    font-weight: bold;
  }

  i {
    font-style: italic;
  }
}

// SAVE NEWS RELEASE SECTION
.m-press-release-downloads {
  margin-top: $gutter;
  padding: $gutter 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  @include tablet {
    flex-direction: row;
  }
  > * {
    flex-basis: 49%;
    &:first-child {
      flex: 0 1 100%;
    }
  }

  a {
    // needed to overwrite `.m-content a` that needs to be a vague selector bc of wagtail's classless structure
    color: #47484a;
  }
}

.m-press-release-btn {
  @include conduit-itc-medium;
  background: $white;
  height: 4rem;
  line-height: 4rem;
  font-size: 1.6rem;
  color: #47484a;
  text-transform: uppercase;
  padding-left: 4rem;
  position: relative;
  margin-bottom: 2rem;
  @include tablet {
    margin-bottom: 0;
    max-width: 49%;
  }

  &::before {
    @include verticalCentre;
    content: "";
    width: 1.9rem;
    height: 2.3rem;
    background-image: url("../img/icon-sprite.png");
    background-position: -0.2rem -30.1rem;
    background-repeat: no-repeat;
    left: 1rem;
  }
  &::after {
    @extend %red-chevron;
  }
}

// EMAIL THE PRESS RELEASE
.m-press-release-email {
  @include conduit-itc-medium;
  background: $white;
  padding: 0.5rem 0.8rem;
  min-height: 3rem;
  position: relative;
  transition: 200ms min-height ease;
  z-index: 0;

  &.has-error {
    $error-message-offset: 4rem;
    @include desktop {
      padding-bottom: $error-message-offset;
    }

    .m-press-release-button {
      top: 2rem;
    }
    .m-press-release-input {
      height: 3rem;
      &::placeholder {
        color: $error;
      }
    }
  }
}

.m-press-release-input {
  @include conduit-itc-medium;
  background: #f0f0f0;
  padding: 0.5rem 1rem;
  height: 3rem;
  border: 0;
  width: 85%;
  font-weight: bold;
  color: #000;
  font-size: 1.6rem;
  @include tablet {
    width: 95%;
  }
  &::placeholder {
    font-weight: bold;
    color: #000;
  }
}

.m-press-release-button {
  @extend %red-chevron;
  background-color: $white;
  border: 0;
}

.m-error-message {
  @include desktop {
    position: absolute;
    bottom: 0;
    left: 0.8rem;
    z-index: 100;
  }
}

.m-disclaimer {
  font-size: 1.4rem;
  color: $error;
}

.m-press-release-article table {
  margin: 0 auto;

  th, td {
    padding: 1rem;

    @include mobile-only {
      padding: 0.8rem;
      width: 25%;
      &:nth-child(2) {
        width: 50%;
      }
    }
  }
  th {
    border-bottom: 0.2rem solid $black;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
    @include mobile-only {
      font-size: 1.4rem;
    }
  }
  td {
    @include mobile-only {
      font-size: 1.2rem;
    }
  }
}

.m-table-subtitle {
  font-weight: bold;
  padding: 1rem 0;
}
