.m-video-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 4rem 0;
  .m-yt-embed {
    display: block;
    width: 100%;
    @include mobile-only {
      height: auto;
      min-height: 19.2rem;
    }

  }
  .m-section-title {
    width: 100%;
  }
}

.m-video-container {
  width: 100%;
  min-height: 19.2rem;
  @include tablet {
    width: 72.6rem;
  }
  @include desktop {
    width: calc(100% - 53.4rem);
  }
}

.m-video-playlist {

  max-height: 42rem;
  width: 100%;
  margin: 1.5rem 0;
  @include tablet {
    width: 72.6rem;
  }
  @include desktop {
    margin: 0;
    padding: 0 1.5rem;
    max-width: 53.4rem;
  }
}

//overriding the position of the scrollbar so that it doesn't overlay the content on mobile
//looked weird with the red border that was added for the active video
.simplebar-track {
  right: -1.5rem;
  @include desktop {
    right: 0;
  }
}

.m-video-playlist-item {
  cursor: pointer;
  min-width: 30rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  border: 0.2rem solid #e9e9e9;
  background-color: #ffffff;
  &.active {
    border-color: $brand-colour-1;
  }
  &:hover &.hover {
    .m-video-thumbnail {
      &::after {
        background: url("../img/video-player/playbuttonhover.png") no-repeat;
        background-size: contain;
      }
    }
  }
}

.m-video-thumbnail {
  width: 20rem;
  position: relative;
  @include tablet {
    width: 26rem;
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 1.9rem;
    width: 2.9rem;
    background: url("../img/video-player/playbutton.png") no-repeat;
    background-size: contain;
  }
}

.m-thumbnail-img {
  display: block;
  width: 100%;
}

.m-video-length {
  position: absolute;
  font-size: 1.2rem;
  line-height: 1.2rem;
  bottom: 0;
  right: 0;
  display: block;
  padding: 0.8rem 1rem;
  background-color: rgba(0,0,0,0.5);
  color: $white;
  .m-shadow_box & {
    bottom: 0.5rem;
    right: 2rem;
    @include tablet {
      bottom: 3rem;
      right: 0.5rem;
    }
    
  }
}

.m-video-description {
  @include conduit-itc-medium;
  padding: 1rem;
  width: 17.5rem;
  @include tablet {
    padding: 2rem 0 0 2.5rem;
  }
  p, h3 {
    margin: 0;
    font-size: 2rem;
    color: $dark-grey;
    @include mobile-only-xs {
      font-size: 1.8rem;
    }
    @include tablet {
      font-size: 2.2rem;
    }
  }
}
