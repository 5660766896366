.m-feature_list {
  padding: 4rem 0;
}

.m-features-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  @include tablet {
    // justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 0; // makes divs equal width, regardless of how many children there are
  }
}

.m-feature-card {
  background: $white;
  display: flex;
  flex-direction: column;

  @include mobile-only {
    width: 100%;
    margin-bottom: 4rem;
  }

  @include tablet {
    width: calc(33.33% - 0.7rem);
    margin-right: 1rem;
    &:nth-of-type(3n + 3) {
      margin-right: 0;
    }

  }

  @include desktop {
    margin-bottom: 0;
    max-width: 40rem;
    width: calc(33.33% - 3.3rem);
    margin-right: 4.9rem;
    &:nth-of-type(3n + 3) {
      margin-right: 0;
    }
  }

  & > a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &:hover, &.hover, &:focus {
      text-decoration: none;
      .m-feature-readmore {
        background-color: $brand-colour-1;
      }
    }
  }
}

.m-feature-link {
  position: relative;

  &:hover,
  &.hover,
  &:focus {
    border: 0;
    text-decoration: none;
    .m-feature-readmore {
      background: $brand-colour-1;
    }
  }
}
.m-feature-image-wrapper {
  width: 100%;
  height: 20rem;
  position: relative;
  overflow: hidden;
  .m-feature-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
    min-height: 100%;
    transform: translate(-50%, -50%);
    @include tablet-only {
      height: 100%;
      width: auto;
      min-height: 0;
    }
  }
  
}

.m-feature-text-wrapper {
  padding: 2rem;
}

.m-feature-title {
  text-transform: uppercase;
  color: $dark-grey;
  font-size: 2.6rem;
  line-height: 1;
}

.m-feature-text {
  @include arial;
  margin-bottom: 3rem;
  color: $black;
  font-size: 1.6rem;
}

.m-feature-readmore {
  @include conduit-itc-medium;
  color: $white;
  position: relative;
  background: #72392c;
  display: block;
  width: 100%;
  height: 4.5rem;
  line-height: 4.5rem;
  padding: 0 2rem;
  text-transform: uppercase;
  transition: 0.3s background-color ease;
  margin: 0;
  text-align: left;
  font-size: 1.6rem;
  border: none;
  cursor: pointer;
  justify-self: flex-end;
  &::after {
    @extend %right-white-chevron;
  }
}