@media screen and (-webkit-min-device-pixel-ratio:0)
and (min-resolution:.001dpcm) {
  img {
    image-rendering: -webkit-optimize-contrast !important;
  }
}

/* Unset for Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  img {
    image-rendering: unset !important;
  }
}}

.l-index {
  margin: 0;
  padding: 0;
  top: 0;
}


// full bleed background sections
.l-section {
  position: relative;
  overflow: hidden;
  &.is-white {
    background: $white;
  }
}

.l-flex-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 5%;
  @include desktop {
    margin: 0;
  }
}

// THIS CONTENT IS AUTOGENERATED BY THE CMS, AND SELECTORS DO NOT HAVE CLASSES.
// THIS IS WHY THERE IS SO MUCH NESTING GOING ON.
.m-content {
  position: relative;
  padding: 0 $mobile-content-padding;
  width: 100%;
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.3rem;

  @include desktop {
    margin: 0 auto;
    max-width: $desktop-width;
    padding: 0 4rem;
  }
  .newsroom & {
    padding-top: 5rem;
  }

  .m-search-results & {
    padding-top: 4rem;
  }

  a {
    color: $brand-colour-1;
    word-break: break-word;
    &.m-button {
      color: $white;
    }
    &.m-news-link {
      display: inline-block;
      color: $black;
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
    }
  }

   ul {
     margin: 0 0 2rem 3rem;
     li {
       position: relative;
       color: $body-copy;
       &::before {
         content:'•';
         position: absolute;
         left: -1rem;
        color: $brand-colour-1;
       }
     }
   }
}

// use this class to visually remove text content
// still allows screen readers to access text
.visually-hidden {
  position: absolute !important;
  height: 0.1rem;
  width: 0.1rem;
  overflow: hidden;
  //can't be set with rems -- a use case for px
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.m-paragraph {
  .m-content > & {
    padding: 4rem 0;
  }
  @include desktop {
    // this needs to be discussed by the team, not all paragraph blocks are the same width.
    // width: 86.5rem;
  }
}

.m-paragraph-short {
  .m-content > & {
    padding: 4rem 0;
  }
  @include desktop {
    width: 86.5rem;
  }
}

.is-gray {
  background: $background-colour;
  position: relative;
  &::before, &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color:$background-colour;
  }

  &::before {
    left: 100%;
    top: 0;
  }
  &::after {
    right: 100%;
    top: 0;
  }
  &.m-products {
    overflow: visible;
  }
}

.is-white {
  background: $white;
}

.cookies-policy , .privacy-policy, .legal-notice {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
}

.privacy-policy .m-page-title,
.legal-notice .m-page-title {
  margin-bottom: 4rem;
}

.cookies-policy main section p,
.legal-notice main section p,
.privacy-policy main section p,
.cookies-policy main section li,
.legal-notice main section li,
.privacy-policy main section li {
  font-size: 2.1rem;
}

.legal-notice main ol li {
  padding: 10px;
}

.legal-notice .m-page-title {
  display: none;
}

.legal-notice .m-section-title {
  color: #72392C;
  font-size: 4.8rem;
}

.legal-notice .m-breadcrumb-wrapper {
  display: none;
}

.legal-notice main h3,
.privacy-policy main h3 {
  text-decoration: underline;
}

.cookies-policy .m-section-title,
.privacy-policy .m-section-title {
  color: #DA291E;
  padding-top: 1rem;
}

.cookies-policy main table,
.privacy-policy main table {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-size: 1.2em;
  margin-bottom: 4rem;
}

.cookies-policy main table th,
.privacy-policy main table th {
  background: #72392c;
  color: white;
  font-weight: bold;
}

.cookies-policy main table th, .cookies-policy main table td,
.privacy-policy main table th, .privacy-policy main table td {
  padding: 10px;
  text-align: left;
}

.cookies-policy main table tbody tr:nth-child(even),
.privacy-policy main table tbody tr:nth-child(even) { background: #F4F4F4 }

.cookies-policy main table tbody tr:nth-child(odd),
.privacy-policy main table tbody tr:nth-child(odd) {background: #D0D0D0}