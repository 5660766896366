.terms {
    ol {
        margin-left: 1.5rem;
        padding-left: 2rem;
        li {
            padding-bottom: 0.5rem
        }
        ol {
            list-style-type: lower-latin;
        }
    }
    
}