// AS PER BRAND GUIDELINES
$brand-colour-1: #da291e;
$brand-colour-2: #ecac00;
$brand-colour-3: #72392c;
$black: #000000;
$white: #ffffff;
$grey: #a1a1a1;
$medium-grey: #82807c;
$dark-grey: #444546;
$dark-brown: #4f291f;
$error: #ff0000;
$background-colour: #f3f2f2;
$brown: #72392c;

$phase-preclinical: #8d817b;
$phase-phase1: #72392c;
$phase-phase2: #ecac00;
$phase-phase3: #da291e;
$phase-filed: #00a29b;


$body-copy: $dark-grey;
$site-width: 102.4rem;
$gutter: 4rem;
$desktop-header-height: 13.9rem;
$desktop-width: 136.3rem;
$desktop-content-width: 130rem;
$mobile-content-padding: 1.7rem;

$arial: arial, helvetica, sans-serif;
