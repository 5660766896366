/*Samsung Breakpoint*/
@mixin mobile-only-xs {
    @media screen and (max-width: 360px) {
        @content;
    }
}

@mixin mobile-only {
    @media screen and (max-width: 767px) {
        @content;
    }
}


@mixin tablet-only {
    @media screen and (min-width: 768px) and (max-width:1024px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 768px) {
        @content;
    }
}

@mixin ipadProPortrait {
    /* Portrait only */
    @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        @content;
    }
}


@mixin desktop {
    @media screen and (min-width: 1024px) and (orientation: landscape) {
        @content;
    }
}


@mixin ultraWide {
    @media screen and (min-width: 1500px) {
        @content;
    }
}


@mixin fullBleed() {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@mixin flexCenter() {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin verticalCentre() {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin horizontalCentre() {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin horAndVertCentre() {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin visuallyHidden {
    border: 0;
    //px and rem behave differently when used in this context -- bext to keep px
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 0.1rem;
    margin: -0.1rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0.1rem;
    word-wrap: normal !important;
  }

  %reset-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

%red-chevron {
    @include verticalCentre;
    content: "";
    width: 0.9rem;
    height: 1.5rem;
    background-image: url("../img/icon-sprite.png");
    background-position: -25px -238px; // pixels bc its a spritemap
    background-repeat: no-repeat;
    right: 1rem;
}

%white-chevron {
    @include verticalCentre;
    content: '';
    background: url("../img/chevron-white.png") no-repeat;
    width: 0.9rem;
    height: 1.5rem;
    right: 1rem;
}

%right-white-chevron {
    @extend %white-chevron;
    height: 2.3rem;
    width: 1.7rem;
    transform: translateY(-50%) rotate(-90deg);
    background-size: contain;
}