
.m-search-form-wrapper, 
.m-results-wrapper {
    padding: 2rem 2.7rem;
    background: $white;
    @include tablet {
        align-items: center;
    }
}

.m-search-form-wrapper {
    flex-direction: column;
    margin: 0;
    margin-bottom: 3.7rem;
    @include tablet {
        flex-direction: row;
    }
}

.m-search-form { 
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    @include tablet {
        width: auto;
        flex-direction: row;
    }
}

.m-search-input {
    font-size: 1.6rem;
    border: none;
    background: #ebebeb;
    flex-grow: 1;
    min-height: 4rem;
    margin-bottom: 1.5rem;
    text-indent: 1.8rem;
    @include tablet {
        min-height: 3.2rem;
        font-size: 1.2rem;
        margin-right: 2rem;
        margin-bottom: 0;
    }

    &::placeholder {
        color: $black;
    }
}

.m-search-submit {
    max-width: 16.3rem;
}

.m-results-label, 
.m-no-results, 
.m-result-title,
.m-result-path {
    margin: 0;
}

.m-results-label, 
.m-no-results,
.m-result-title {
    font-weight: 700;
}

.m-results-label {
    margin-bottom: 1rem;
    @include tablet {
    margin-bottom: 0;
    }
}

.m-result-title {
    margin-bottom: 0.5rem;
    color: #444546;
}

.m-result-path {
    @include conduit-itc-medium;
    font-size: 1.4rem;
    color: #333435;
}

.m-results-label {
    @include tablet {
        width: 20%;
    }
}

.m-result {
    color: inherit;
    border-top: 0.1rem solid #e2e5e7;
    display: block;
    margin-bottom: 2rem;
    padding-right: 1.5rem;
    padding-top: 2rem;
    background: url(../img/chevron-red.png) no-repeat right calc(50% + 1rem);
    &:first-child {
        border-top: none;
    }
    &:hover {
        text-decoration: none;
    }
}
