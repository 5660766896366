.m-news-events {
    padding: 0;
    margin-bottom: 0;
    .m-column {
        width: 100%;
        padding-left: 6rem;
        padding-top: 0.8rem;
        @include desktop {
            padding: 0;
            padding-left: 6rem;
            padding-top: 0.8rem;
            width: calc(50% - 2.5%);
            
        }
    }

    .m-column:first-child {
        margin-bottom:  6rem;
        @include desktop {
            margin-bottom: 0;
            margin-right: 5%;
        }
    }
    
}