
// A BASE rule is applied to an element using an element selector, a descendent selector, or a child selector, along with any pseudo-classes.
// It doesn’t include any class or ID selectors.


/* Eric Meyer's Reset CSS v2.0 - http://cssreset.com */
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{border:0;font-size:100%;font:inherit;vertical-align:baseline;margin:0;padding:0}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}table{border-collapse:collapse;border-spacing:0}

*,
*::before,
*::after {
    // -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    line-height: 1.3;
    margin: 0;
    padding: 0;
}


html {
    font-size: 10px; /* so that 1rem = 10px (because the standard browser font size is 16px)
                        Have to use 10px and not 62.5% because IE11 was not calculating properly causing issues in layout*/
    padding: 0;
    margin: 0;
}


body {
	@include arial;
    color: $body-copy;
    background: $white;
    font-size: 1.6rem;
    overflow-x: hidden;
}


main {
    @include desktop {
        position: relative;
        top: 13.5rem;
    }
}

h1, h2, h3, h4, h5, h6 {
    @include conduit-itc-medium;
    color: $body-copy;
    font-weight:normal;
    margin: 0;
    padding: 0;
}


h1 {
    color: $brand-colour-3;
    font-size: 4.8rem;
    line-height: 5.0rem;
    padding: 0 0 1.2rem 0;

    &:not(.m-homepage-hero-title) {
        text-transform: uppercase;
    }
}

    h2 {
        font-size: 2.6rem;
        line-height: 3.6rem;
        text-transform: uppercase;

        a {
            color: inherit;
            &:hover {
                text-decoration: underline;
            }
        }
    }

h3 {
    font-size: 2rem;
    line-height: 1.2;
    color: $black;
    display: inline-block;
    position: relative;
    a {
        color: inherit;
        &:hover {
            text-decoration: underline;
        }
    }
}
p {
    color: $body-copy;
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin: 0.75rem 0;
}

sub {
    font-size: 62.5%;
    vertical-align: text-bottom;
}

sup {
    font-size: 75%;
    vertical-align: super;
}
strong, b {
    font-weight: bold;
}

em {
    font-style: italic;
}

a {
    color: $brand-colour-1;
    text-decoration: none;
    &:hover, &:focus, &.hover {
       text-decoration: underline;
    }
}

ol {
    list-style: decimal;
}