
.m-shadowbox {
	
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
	padding-top: $gutter;
	height: auto;
	@include tablet {
		height: 21rem;
		flex-direction: row;
	}
}

.m-shadowbox-link {
	@include conduit-itc-medium;
	display:block;
	color:$white;
	background-color: $white;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
	padding: 3rem 1.5rem 0rem 1.5rem;
	position: relative;
	&:hover {
		cursor: pointer;
		.m-shadowbox-label {
			text-decoration: none;
	}
	}

	@include tablet {
		padding: 0;
		width:26rem;
		height:17rem;
	}

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 1.9rem;
		width: 2.9rem;
		background: url(../img/video-player/playbutton.png) no-repeat;
		background-size: contain;
	}
}

.m-shadowbox-thumbnail {
	max-width: 100%;
	width: 100%;
	height: auto;
	
	@include tablet {
		width: auto;
	}
}

.m-shadowbox-label {
	@include conduit-itc-medium;
	color: $white;
	margin: 0;
	text-transform: uppercase;
	font-size:1.6rem;
	background: $brand-colour-3;
	padding: 1rem;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;

	&::after {
	@extend %right-white-chevron;

	}
	@include tablet {
		padding: 0.1rem 0 0.1rem 1rem;
		position: relative;
	}
	&.mobile-only {
		@include tablet {
			display: none;
		}
	}
	&.desktop-only {
		display: none;
		@include tablet {
			display: block;
		}
	}
	&:hover {
		cursor: pointer;
	}
}

.m-shadowbox img.shadow {
	width:100%;
	height:1.8rem;
}

.m-shadowbox-data {
	width: 100%;
	height: 100%;
	background:$white;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 1.5rem 1.5rem 5.5rem 1.5rem;
	@include tablet {
		width: calc(100% - 25rem);
		padding: 0 2rem;
	}
}

.m-shadowbox-title {
	color: $black;
	margin-top: 1rem;
	@include tablet {
		margin-top: 0;
	}
}

.m-shadowbox p:not(.m-shadowbox-label) {
	font-size: 1.4rem;
	line-height: 1.6rem;
	margin: 0;
	color: #444546;
}
