/*eyebrow nav*/
.l-eyebrow-nav {
  display: none;
  @include desktop {
    color: $dark-grey;
    text-align: left;
    padding-bottom: 0;
    text-transform: uppercase;
    width: 100%;
    height: 2.4rem;
    overflow: hidden;
    display: block;

    a {
      color: $dark-grey;
    }
  }
}

.m-eyebrow-nav-links {
  @include conduit-itc-medium;
  font-weight: normal;
  list-style-type: none;
  margin: 0;
  float: right;
  padding: 0.8rem 2.6rem 0 0;
  line-height: 1.5;

  .first,
  .last {
    display: inline;
    padding: 0 0.4rem;
    border-right: 0.1rem solid #878585;

    a {
      &:hover {
        text-decoration: underline;
        color: $brand-colour-1;
      }
    }

    &.last {
      border: none;
    }
  }


}

/* main navigation */
.l-nav {
  @include conduit-itc-medium;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  width: 100%;
  font-size: 2.5rem;
  line-height: 3.4rem;
  max-height: 0;
  overflow:hidden;
  transition: 200ms max-height ease-in-out;
  @include desktop {
    max-height: none;
    font-size: 2rem;
    overflow: visible;
  }
  &.active {
    max-height: 100rem;
    overflow: auto;
  }
}

.m-nav-top {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  @include desktop {
    height: 3.4rem;
    display: flex;
  }
}

.m-nav-top-item {
  position: relative;
  transition: 200ms background-color ease-in-out;

  @include desktop {
    height: 3.4rem;
    flex-grow: 1;
    flex-basis: 0;

    &:hover,
    &.hover,
    &:focus {
      .m-subnav {
        display: block;
      }
    }
  }

  &.open {
    .m-subnav {
      max-height: 50rem;
    }
    i {
      transform: rotate(90deg);
    }
  }


  &:hover,
  &.hover,
  &.active,
  &.ancestor {
    @include desktop {
      .m-nav-link {
        background-color: #8b0406;
      }
    }
  }
  &.last {
    border: none;
  }
}

.m-nav-link {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: $brand-colour-1;
  display: block;
  color: $white;
  border-bottom: 0.1rem solid #FFF;
  box-shadow: 0 1rem, 1.3rem rgba(0,0,0,0.3);
  z-index: 2;
  padding: 1.5rem 4rem 1.5rem 1.5rem;
  position: relative;
  @include desktop {
    border-right: 0.1rem solid $white;
    border-bottom: none;
    box-shadow: none;
    padding:0 0 0 1.5rem;
  }
  &:hover, .hover, &:focus {
    text-decoration: none;
    & + .m-subnav {
      display: block;
    }
  }

  @include tablet-only {
    font-size: 1.9rem;
    padding-left: 3%;
  }
}

.m-nav-link i {
  background: url("../img/white-chevron-large.png") top left no-repeat;
  background-size: cover;
  width: 1rem;
  height: 1.7rem;
  right: 1.5rem;
  top: 50%;
  margin-top: -0.9rem;
  position: absolute;
  transition: 200ms transform ease-in-out;
  @include desktop {
    display: none;
  }
}

// subnav drop down menu
.m-subnav {
  background: url("../img/nav-drop-bg-light.png") 0 0;
  left: 0;
  top: 0;
  transition: 200ms max-height ease-in-out;
  transition-delay: 0ms;
  z-index: -1;
  height: auto;
  overflow: hidden;
  max-height: 0;
  @include desktop {
    transition: 200ms transform ease-in-out;
    max-height: none;
    display: none;
    width: inherit;
  }

  &.active {
    transition-delay: 300ms;
  }

  &.selected {
    &.forceactive a {
      color: #fbfcfc;
    }
  }
}

.m-subnav-item {
  background: rgba(95,95,95,0.97);
  @include desktop {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.m-subnav-link {
  color: #AAA;
  padding: 1.5rem 4rem 1.5rem 1.5rem;
  white-space: normal;
  line-height: 1;
  width: 100%;
  display: block;
  @include desktop {
    color: $white;
    padding: 0.5rem 1.5rem;
    width: inherit;
  }
  &.active {
    color: $white;
  }
  &:hover,
  &:focus,
  &.hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.25);
  }
}