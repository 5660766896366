$canada-width: 20.7rem;
$canada-height: 17.1rem;
$usa-width: 23.7rem;
$usa-height: 15.5rem;
$uk-width: 2.3rem;
$uk-height: 3.1rem;
$china-width: 14rem;
$china-height: 10.3rem;
$japan-width: 4rem;
$japan-height: 4.2rem;
$singapore-width: 5rem;
$singapore-height: 5rem;
$eu-width: 4.6rem;
$eu-height: 3rem;
$switzerland-width: 4rem;
$switzerland-height: 3rem;

// -----------------
// FLAG ICONS
// -----------------
[class^="m-flag"] {
  position: absolute;
  display: block;
  &.hide {
   visibility: hidden;
  }
}

.m-flag-us {
  background: url("../img/map/flag-usa.png") no-repeat;
  background-size: cover;
  width: 2.6rem;
  height: 1.7rem;
}

.m-flag-jp {
  background: url("../img/map/flag-japan.png") no-repeat;
  background-size: cover;
  width: 2.7rem;
  height: 1.8rem;
}

.m-flag-ca {
  background: url("../img/map/flag-canada.png") no-repeat;
  background-size: cover;
  width: 2.6rem;
  height: 1.7rem;
}

.m-flag-gb {
  background: url("../img/map/flag-uk.png") no-repeat;
  background-size: cover;
  width: 2.6rem;
  height: 1.7rem;
}

.m-flag-sg {
  background: url("../img/map/flag-singapore.png") no-repeat;
  background-size: cover;
  width: 2.7rem;
  height: 1.8rem;
}

.m-flag-cn {
  background: url("../img/map/flag-china.png") no-repeat;
  background-size: cover;
  width: 2.6rem;
  height: 1.7rem;
}

.m-flag-eu {
  background: url("../img/map/flag-eu.png") no-repeat;
  background-size: cover;
  width: 2.6rem;
  height: 1.7rem;
}

.m-flag-ch {
  background: url("../img/map/flag-switzerland.png") no-repeat;
  background-size: cover;
  width: 2.6rem;
  height: 1.7rem;
}

// -----------------
//MAP OF COUNTRIES
// -----------------
.l-map-wrapper {
  background: #f3f2f2;
  width: 100%;
  height: auto;
  position: relative;
  @include desktop {
  // Preload the overlay flags, so that there is no flicker
    background-image: url("../img/map/map-canada.png"),
                      url("../img/map/map-usa.png"),
                      url("../img/map/map-uk.png"),
                      url("../img/map/map-china.png"),
                      url("../img/map/map-japan.png"),
                      url("../img/map/map-eu.png"),
                      url("../img/map/map-switzerland.png");
    background-repeat: no-repeat;
    background-position: -100%;    
    @include fullBleed();
    height: auto;
  }
}

.m-map {
  display: none;
  @include desktop {
    background: #f3f2f2 url("../img/map/map-bg.png") no-repeat top 5rem center;
    width: $site-width;
    height: 60rem;
    position: relative;
    margin: 0 auto;
    display: block;

  }
}

.m-country-wrapper {
  position: absolute;

  &[data-country="CA"] {
    width: $canada-width;
    height: $canada-height;
    left: 15.6rem;
    top: 8.7rem;
    z-index: 1;
  }

  &[data-country="US"] {
    width: $usa-width;
    height: $usa-height;
    left: 9.3rem;
    top: 15.2rem;
  }

  &[data-country="GB"] {
    width: $uk-width;
    height: $uk-height;
    left: 46.8rem;
    top: 20.2rem;
  }

  &[data-country="CN"] {
    width: $china-width;
    height: $china-height;
    left: 65.8rem;
    top: 22.2rem;
  }

  &[data-country="JP"] {
    width: $japan-width;
    height: $japan-height;
    left: 78.5rem;
    top: 24.8rem;
  }

  &[data-country="SG"] {
    width: $singapore-width;
    height: $singapore-height;
    left: 71.8rem;
    top: 34.2rem;
  }

  &[data-country="EU"] {
    width: $eu-width;
    height: $eu-height;
    left: 50rem;
    top: 19.0rem;
  }

  &[data-country="CH"] {
    width: $switzerland-width;
    height: $switzerland-height;
    left: 50.5rem;
    top: 23.6rem;
  }  
}

// -----------------
// COUNTRY TRIGGER AREAS
// -----------------
.m-country-trigger-area {
  color: $black;
  font-size: 4rem;
  position: absolute;
  width: 3rem;
  height: 3rem;

  &:hover, &.hover, &:focus {
    text-decoration: none;
    color: $brand-colour-1;
    [class^="m-pip"] {
      z-index: 1;
    }
    span[class^="m-pip"]::before  {
        content: '';
        position: absolute;
        height: calc(100% + 2px);
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $white;
        border-radius: 100%;
        z-index: -1;
    }
  }

  [data-country="CA"] & {
    left: 11.1rem;
    top: 13.8rem;
    width: 4rem;
  }

  [data-country="US"] & {
    &.is-nj {
      right: -1.5rem;
      top: 10.9rem;
      width: 4rem;
      &:hover, &:focus, &.hover {
        z-index: 99;
      }
    }
    &.is-ca {
      top: 10.8rem;
      left: 7rem;
      width: 4rem;
      .m-pip-us {
        right: 0;
      }
    }
  }

  [data-country="GB"] & {
    left: -1.7rem;
    top: 1.5rem;
    width: 4rem;
  }

  [data-country="CN"] & {
    left: 6.5rem;
    top: 1.6rem;
    width: 3.5rem;
    &:hover, &:focus, &.hover {
      z-index: 99;
    }
  }

  [data-country="JP"] & {
    left: -0.3rem;
    top: 2.4rem;
    width: 6rem;
  }

  [data-country="SG"] & {
    bottom: 0;
    width: 4rem;
  }

  [data-country="EU"] & {
    left: -0.2rem;
    width: 4.6rem;
    height: 2.5rem;
  }

  [data-country="CH"] & {
    bottom: 0;
    width: 4rem;
    left: 0.25rem;
  }


  // -----------------
  // COUNTRY LOCATION PIPS
  // NOTE: US pips are within .m-country-trigger-area bc there is additional complexity
  // -----------------
  [class^="m-pip"] {
    position: absolute;
    font-size: 3.5rem;
    line-height: 1rem;
    display: block;
  }

  // canada
  .m-pip-ca {
    right: 0;
    bottom: 0;
  }

  // uk-eu
  .m-pip-gb {
    right: 0.2rem;
    top: 0.2rem;
  }

  // china
  .m-pip-cn {
    bottom: -0.3rem;
    right: -0.2rem;
    height: 1.2rem;
    line-height: 1.2rem;
  }

  // japan
  .m-pip-jp {
    top: 0.1rem;
    left: 2.1rem;
  }

  //singapore
  .m-pip-sg {
    top: 0.1rem;
  }

  // eu
  .m-pip-eu {
    top: 1.3rem;
    left: 0.7rem;
  }
  
  //switzerland
  .m-pip-ch {
    top: 0.1rem;
    z-index: 1;
  }
  
  // -----------------
  // COUNTRY LOCATION FLAGS
  // -----------------
  .m-flag-ca {
    left: 0.2rem;
    top: 0.2rem;
  }

  .m-flag-gb {
    top: 1.3rem;
    left: 0;
  }

  .m-flag-cn {
    left: 0;
    top: 0.3rem;
  }

  .m-flag-jp {
    top: 1rem;
    left: 3.2rem;
  }

  .m-flag-sg {
    top: 1.2rem;
    left: 1.3rem;
  }

  .m-flag-eu {
    left: 2.3rem;
  }

  .m-flag-ch {
    top: 1.2rem;
    left: 1.3rem;
    z-index: 1;
  }

  &.is-nj {
    .m-flag-us {
      left: 1.2rem;
      top: 1rem;
    }
  }

  &.is-ca {
    .m-flag-us {
      left: 0.2rem;
      top: 1.2rem;
    }
  }
}

// -----------------
// COUNTRY MAPS
// NOTE: Singapore doesnt have a country map as its too small.
// -----------------
.m-country-map {
  position: absolute;
  display: none;
  left: 0;
  top: 0;

  [data-country="CA"] & {
    &.current {
      width: $canada-width;
      height: $canada-height;
      background: url("../img/map/map-canada.png") no-repeat;
      display: block;
    }
  }

  [data-country="US"] & {
    &.current {
      width: $usa-width;
      height: $usa-height;
      background: url("../img/map/map-usa.png") no-repeat;
      display: block;
    }
  }

  [data-country="GB"] & {
    &.current {
      width: $uk-width;
      height: $uk-height;
      background: url("../img/map/map-uk.png") no-repeat;
      display: block;
    }
  }

  [data-country="CN"] & {
    &.current {
      width: $china-width;
      height: $china-height;
      background: url("../img/map/map-china.png") no-repeat;
      display: block;
    }
  }

  [data-country="JP"] & {
    &.current {
      width: $japan-width;
      height: $japan-height;
      background: url("../img/map/map-japan.png") no-repeat;
      display: block;
    }
  }

  [data-country="EU"] & {
    &.current {
      width: $eu-width;
      height: $eu-height;
      background: url("../img/map/map-eu.png") no-repeat;
      display: block;
      left: -3.85rem;
      top: -3.6rem;
      height: 13rem;
      width: 10rem;
    }
  }

  [data-country="CH"] & {
    &.current {
      width: $switzerland-width;
      height: $switzerland-height;
      background: url("../img/map/map-switzerland.png") no-repeat;
      display: block;
      left: -0.4rem;
      top: 0.3rem;
    }
  }


}

// -----------------
// LOCATION ADDRESS LIST
// -----------------
.l-map-locations {
  padding: 2rem;
  margin: 5rem auto;
  background: $white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  @include desktop {
    width: $site-width;
    height: 103rem; //NOTE: needs to be hard-set so that the locations wrap
    flex-direction: column;
    padding: 0;
  }
}

.m-address {
  width: 100%;
  margin-bottom: 3rem;

  @include desktop {
    width: 50%;
    min-height: 18rem;
    margin-bottom: 0;
  }
  [class^="m-flag"] {
    position: relative;
    display: block;
    @include mobile-only {
      margin-bottom: 1rem;
    }
    @include tablet {
      display: inline-block;
    }
  }
}

.m-address-title {
  @include conduit-itc-medium ;
  text-transform: uppercase;
  @include mobile-only {
    line-height: 1;
  }
  @include tablet {
    display: inline-block;
  }
}

.m-address-text {
  margin: 0;
  @include arial;
  margin-right: 1.5rem;
  @include tablet {
    margin-left: 3.2rem;
  }
}

.m-tel-link {
  text-decoration: none;
  color: $dark-grey;
}



.m-location-website {
  text-decoration: underline;
  @include tablet {
    margin-left: 3rem;
  }

  &:hover,&.hover, &:focus {
    text-decoration: none;
  }
}

// -----------------
// INFO BOX
// -----------------
.m-info-box {
  width: 31rem; //400px
  height: auto;
  background: $white;
  border: 0.2rem solid $medium-grey;
  position: absolute;
  display: none;
  z-index: 10;
  box-shadow: 0.3rem 0.3rem 0.5rem -0.2rem rgba(0, 0, 0, 0.25);
  padding: 0.5rem 1rem;

  [data-country="CA"] & {
    top: 3rem;
    left: 15rem;
    width: 32rem;
  }

  [data-country="GB"] & {
    left: 2rem;
    top: -10rem;
    width: 30rem;
  }

  [data-country="CN"] & {
    top: -9.6rem;
    left: -28.9rem;
    width: 38rem;
  }

  [data-country="JP"] & {
    top: 3.5rem;
    left: -30.2rem;
    width: 32rem;
  }

  [data-country="SG"] & {
    left: -34.9rem;
    top: 3.2rem;
    width: 35rem;
  }

  [data-country="EU"] & {
    top: -8.5rem;
    left: 5.2rem;
    width: 32rem;
  }

  [data-country="CH"] & {
    left: 3.5rem;
    top: 3.2rem;
    width: 35rem;
  }


  &.show {
    display: block;
    & > span {
      visibility: visible;
    }
  }

  .m-address-title {
    @include conduit-itc-bold ;
    font-size: 1.8rem;
    margin-left: 0.5rem;
    line-height: 2.2rem;
  }

  .m-address-text {
    @include conduit-itc-medium;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 3.5rem;
  }

  .m-web-address-text {
    margin-top: 1rem;
  }

  [class^="m-flag"] {
    position: relative;
    display: inline-block;
  }
}

// US-NJ info box location
.is-nj + .m-info-box {
  top: 12rem;
  left: 22.4rem;
}

// US-NJ info box location
.is-ca + .m-info-box {
  top: 12rem;
  left: 11rem;
  width: 28rem;
}

// -----------------
// MAP PAGE FOOTER
// -----------------

.l-footer-wrapper {
  .index-map & {
    width: 100%;
    margin: 2rem auto;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 2rem;

    @include desktop {
      width: $site-width;
      padding: 0;
    }

    .m-copyright {
        margin: 0;
    }
    .m-corporate-logo {
      width: 20rem;
      img {
        max-width: 100%;
        height: auto;
      }
    }

    .m-footer-menu {
      margin-top: 2rem;
      li:first-child {
        padding-left: 0;
      }
    }
  }
}