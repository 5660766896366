.l-header-wrapper {
  width: 100%;
  font-size: 1.4rem;
  color: $dark-grey;
  background: $white;
  z-index: 2000;
  position:relative;
  height:auto;

  @include desktop {
    position: fixed;
    top: 0;
    left: 0;
    height: $desktop-header-height;
  }
}

.l-header {
  position: relative;
  width: auto;
  margin: 0 auto;
  overflow: auto;
  overflow: visible;
  @include desktop {
    min-width:76.8rem;
    max-width: 136.6rem;
  }
}

.m-skip-links {
  padding: 1rem;
  position: absolute;
  top: -4.5rem;
  left: 1rem;
  z-index: 1000;
  color: transparent;
  color: $white;
  background-color: $brand-colour-1;
  &:focus {
    top: 1.5rem;
  }
}


