.m-hero-wrapper {
  @include fullBleed();
  position: relative;
  background: $white;
}

.m-hero {
	background-color: $white;
  position: relative;
  max-width: $desktop-width; // weird width to accomodate image positioning with text block
  margin: 0 auto 0;
  display: flex;
  padding: 0 1.7rem;

  @include desktop {
    padding: 0;
    justify-content: space-between;
  }

  .press-releases & {
    padding-bottom: 2.5rem;
  }


}

.m-hero-copy {
  position: relative;
  font-size: 1.6rem;
  line-height: 2.3rem;
  z-index: 20;
  width: 100%;
  @include desktop {
    width: 60%;
    margin-left: 4rem;
  }

  &.is-fullwidth {
    width: 100%;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 3rem;
    color: #444546;
  }
  h2 {
    padding: 0;
    font-size: 2.6rem;
    margin-bottom: 2rem;
    line-height: 1.2;
    color: $black;
  }
}

.m-hero-image {
  display: none;

  @include desktop {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 51.2rem;
    margin-right: 0;
    max-width: 100%;
    img {
      position: relative;
      z-index: 1;
      max-width: 100%;
    }
  }
}

.no-hero {
  @include fullBleed();
  background: $white;
}

.rich-text { // move to typogrpahy
  ul {
    @extend %reset-ul;
    margin: 1.5rem;

    @include desktop {
      margin: 1.5rem 0 3rem  5.5rem;
    }

    li {
      color: $body-copy;
      position: relative;
      margin-bottom: 0.5rem;
      padding-left: 1rem;
      &:before {
        content: '•';
        color: $brand-colour-1;
        font-size: 2rem;
        position: absolute;
        left: -1rem;
      }
    }

    ul {
      margin-top: .5rem;
    }
  }
}

// homepage hero
.template-homepage {
  .m-hero-wrapper {
    height: 64rem;
    overflow: hidden;
    @include desktop {
      height: 51.3rem;
    }
  }

  .m-hero {
    background: url("../img/home-bg-mobile.jpg") no-repeat left -5rem top;
    height: 64rem;
    flex-direction: column;

    @include tablet  {
      background-position: left top;
    }
    @include desktop {
      background: none;
      height: 53rem;
      max-width: 136.6rem;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
    }
  }
}

.m-homepage-hero-video {
  display: none;
  @include desktop {
    display: block;
    z-index: 0;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto; // needed to fill
  }
}


//HOMEPAGE HERO TEXT
.m-homepage-hero-text-wrapper {
  z-index: 100;
  width: 25rem;
  align-self: flex-end;
  margin-top: 2rem;
  @include desktop {
    width: 42rem;
    margin: 5.5rem 8.2rem 0 0;
    align-self: flex-start;
  }
}

.m-homepage-hero-title {
  font-size: 5.3rem;
  line-height: 5.5rem;
  color: $black;
  display: block;
  .m-homepage-hero-title-1 {
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: $brand-colour-3;
    display: block;
    @include mobile-only {
      width: 70%;
    }
  }
}

.m-homepage-hero-paragraph {
    color: $brand-colour-3;
    font-size: 1.4rem;
    line-height: 1.7rem;
}

.m-homepage-hero-btn {
  @include conduit-itc-medium;
  display: block;
  width: 19rem;
  height: 5.1rem;
  font-size: 1.9rem;
  background: url("../img/btn-learn-more.png") no-repeat;
  color: $white;
  text-transform: uppercase;
  padding-top: 1.6rem;
  padding-left: 2.3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  float: right;
}