.m-modal-overlay {
  display: none;
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  &.show {
    display: block;
  }
}

.no-scroll {
  overflow: hidden;
}

.m-modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  width: 90vw;
  max-width: 100rem;
  background-color: white;
  padding: $gutter;
  min-height: 30rem;
  max-height: calc(100% - 30px);
  overflow-y: auto;

  &.show {
    display: block;
    &.m-leadership-card-modal {
      display: flex;
    }
  }

  &.is-press-release {
    @include desktop {
      width: 42rem;
      padding: 8rem 5rem;
      box-sizing: content-box;
    }
  }

  @include desktop {
    min-width: 50rem;
  }
}

.m-modal-title {
  @include conduit-itc-medium;
  color: $brand-colour-1;
  text-align: left;
  padding: 2rem;
  font-size: 2.4rem;

  @include desktop {
    font-size: 4.8rem;
  }
}

.m-modal-content {
  text-align: left;
  font-size: 1.4rem;
  padding: 2rem;
  background-color: $white;

  @include desktop {
    font-size: 1.6rem;
  }

  p {
    font: normal 1.6rem/1.2 $arial;
    color: $black;
  }

  ul {
    list-style-type: disc;
    padding-left: 2rem;
    margin-left: 0;
    color: $black;

    li {
      margin-bottom: 1rem;
      color: $black;
      &::before {
        display: none;
      }

    }
  }

  a {
    font: bold 1.6rem/1.2 $arial;
    color: $brand-colour-1;
    margin-top: $gutter;
    display: inline-block;
    text-decoration: underline;
    &:hover,
    &.hover,
    &:focus {
      text-decoration: none;
    }
  }
 
}

.m-modal-video iframe {
  max-width: 100%;
  height: auto;
  min-height: 50.9rem;
  @include mobile-only {
    min-height: 0;
  }
}

.m-modal-video iframe {
  max-width: 100%;
  height: auto;
  min-height: 509px;
  @include mobile-only {
    min-height: 0;
  }
}

.m-modal-actions {
  width: 75%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.m-modal-button {
  width: 48%;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.m-modal-data {
  color: $black;
  margin: 0;
  padding: 0;
  display: flex;
  margin-bottom: 1rem;

  dt,
  dd {
    margin: 0;
    padding: 0;
  }
  dt {
    width: 12rem;
    font: bold 1.6rem $arial;
  }
  dd {
    font: normal 1.6rem $arial;
    text-transform: capitalize;
  }
}

.m-modal-hr {
  border-top: 0.1rem solid #8d817b;
  margin: $gutter 0;
}

// EMAIL PRESS RELEASE
[data-modal-name="release-email-response"] {
  text-align: center;

  .m-modal-title {
    @include conduit-itc-medium;
    color: $brand-colour-1;
    text-transform: uppercase;
    font-size: 5.1rem;
    line-height: 5.1rem;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
  }

  .m-modal-copy {
    text-align: center;
    margin-top: 0;
  }
}


[data-modal-name="leadership"] {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  @include desktop {
    flex-direction: row;
    align-items: flex-start;
    .m-leadership-info,
    .m-leadership-description {
      width: 50%;
    }
  }
  
}

