.contact {
  .m-breadcrumb-wrapper {
    // visibility: hidden;
  }

  .l-section {
    margin-top: 4rem;
  }


  .m-content {
    @include mobile-only {
      width: 100%;
      margin: 0;
      padding: 2rem;
    }
    p {
      margin: 0 0 0.5rem;
    }

    @include desktop {
      padding: 1rem;
    }
  }

  .m-button-arrow {
    margin-top: 1rem;
  }
}



// two column layout
.m-contact-layout {
  display: flex;
  flex-direction: column;
  padding: 3rem 0 0;
  margin: 0 auto;

  @include tablet {
    padding: 0;
    margin-bottom: 0.8rem;
    flex-direction: row;
  }

  @include desktop {
    max-width: $desktop-width;
    padding: 3rem 3rem 0.1rem 3rem;
  }
}



.m-contact-info {
  margin-bottom: 3rem;

  &:last-child {
    @include mobile-only {
      margin-bottom: 0;
    }
  }
}

.m-contact-map {
  width: 100%;
  height: 25rem;
  border: 0.1rem solid #e1e1e1;

  @include desktop {
    width: 60rem;
    height: 36rem;
    margin-top: 4rem;
  }
}