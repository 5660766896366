.m-recent-content-block-wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @include desktop {
    flex-direction: row;

  }
}

.m-recent-content-block {
  padding: $gutter 0 $gutter*2;
  position: relative;
  margin-bottom: 4rem;
  
  @include tablet {
    margin-bottom: 0
  }

  @include desktop {
    margin-bottom: 0;
    max-width: 40rem;
    width: calc(33.33% - 3.3rem);
    margin-right: 4.9rem;
    &:nth-of-type(3n + 3) {
      margin-right: 0;
    }
  }

  .m-button-arrow {
    position: absolute;
    bottom: 4rem;
  }
}

.m-recent-content-title {
  text-transform: uppercase;
  font-size: 2.6rem;
  color: #8d817b;
  padding-top: 0.8rem;
  padding-left: 6rem;
  padding-bottom: 2.6rem;

  &.is-press-releases {
    background: url("../img/icon-press-release.png") no-repeat left top;
  }
  &.is-social-responsibility {
    background: url("../img/icon-corporate-responsibility.png") no-repeat left top;
  }
  &.is-job-postings {
    background: url("../img/icon-job-postings.png") no-repeat left top;
  }
}



.m-content .m-recent-content-link,
.m-content .m-recent-content-text {
  font-size: 1.4rem;
  color: $black;
  margin-top: 0;
}


.m-content .m-recent-content-list {
  margin: 0;
}
.m-recent-content-list-item {
  margin-bottom: 2rem;
  &:before {
    display: none;
  }
}