.l-sitemap-wrapper {
  color: $dark-grey;
  margin: 0 auto;
  overflow: auto;
  background: $background-colour;
  padding: $gutter 0;
}

.m-sitemap {
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  padding: 0 $mobile-content-padding;


  @include desktop {
    max-width: $desktop-content-width;
  }

  > ul {
    @extend %reset-ul;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include desktop {
      flex-direction: row;
    }
  }
}

.m-sitemap-column {
  @include mobile-only {
    display: inline-block;
    width: 100%;
  }
}

.m-sitemap-header {
  padding-top: 4rem;
  font-size: 2rem;
  line-height: 1;

  @include desktop {
    padding-top: 0;
  }

  a {
    @include conduit-itc-medium;
    color:$brand-colour-3;
    text-transform: none;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-size: 2rem;
    @include desktop {
      margin-bottom: 1.5rem;
    }
  }
}

.m-sitemap-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  li {
    margin-bottom: 0.5rem;
  }
}

.m-sitemap-link {
  @include conduit-itc-medium;
  color: $dark-grey;
  font-size: 1.5rem;
  line-height: 1.2;
  text-transform: capitalize;
  &:hover,
  &.hover,
  &:focus {
    text-decoration: underline;
  }
}
