// TODO:
// Please confirm with Brian Amal in Klick Production that this license is still valid. Ticket: https://genome.klick.com/tickets/#/details/1606477


@font-face{
  font-family:"Conduit ITC W01 Medium";
  src:url("../fonts/a5a0edc0-b571-4275-9c51-3dfeba35b75a.eot?#iefix");
  src:url("../fonts/a5a0edc0-b571-4275-9c51-3dfeba35b75a.eot?#iefix") format("eot"),url("../fonts/a81af59b-a5d9-4e96-a7cc-919403c0d86c.woff2") format("woff2"),url("../fonts/e93ee223-5d52-4bdf-a113-c6c4c8936824.woff") format("woff"),url("../fonts/ccdadc2e-26c9-48a5-9c52-9c3cc58e9930.ttf") format("truetype");
}
@font-face{
  font-family:"Conduit ITC W01 Bold";
  src:url("../fonts/ef1cf8c3-989e-4b7e-ad89-9e034d47686d.eot?#iefix");
  src:url("../fonts/ef1cf8c3-989e-4b7e-ad89-9e034d47686d.eot?#iefix") format("eot"),url("../fonts/4680ad80-371f-497c-8926-35654adc2249.woff2") format("woff2"),url("../fonts/fdaf48d4-c023-4a03-b948-53535ee4d571.woff") format("woff"),url("../fonts/ca6dc25e-d400-4735-9fb9-ee2d025ebb5c.ttf") format("truetype");
}

// FONT MIXINS
@mixin conduit-itc-medium {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
}

@mixin conduit-itc-medium-italic {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: italic;
}


@mixin conduit-itc-bold {
  font-family: "Conduit ITC W01 Bold", Arial, sans-serif;
  font-style: normal;
}

@mixin arial {
  font-family: arial, helvetica, sans-serif;
}