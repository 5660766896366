.job-postings .m-content {
  display: flex;
  justify-content: center;
  width: calc(100% - 3.4rem);
  @include tablet {
    width: 100%;
  }
}


.m-job-postings {
  margin: 1rem auto 4rem;
  height: 85rem;

  @include tablet {
    //iframe comes in with a set width and needs the important to override on desktop
    width: 100% !important;
    height: 65rem;
    display: block;
    
  }
}


