.m-leadership {
    .m-content {
        &.show {
            display: block;
        }
        &.hide {
            display: none;
        }
    }
}

.m-leadership-filters {
    @include conduit-itc-medium;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    
    padding: 4rem 0;
    @include tablet {
        flex-direction: row;
        align-items: center;
    }
    p {
        margin: 0 0 1.5rem 0;
        @include tablet {
            margin: 0 1.5rem 0 0;
        }
    }
    .m-leadership-filter {
        text-align: left;
        margin-bottom: 1.5rem;
        min-width: 21.5rem;
        padding: 0.7rem 3rem 0.7rem 1.6rem;
        @include tablet {
            margin: 0 1.5rem 0 0;
        }
        &.active {
            background-color: $dark-brown;
        }
    }
}

.m-leadership-filter {
    @include conduit-itc-medium;
    text-transform: uppercase;
}
.m-leadership-title {
    width: 100%;
}

.m-leadership-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: column;
    @include tablet {
        flex-direction: row;
    }
    
}

.m-leadership-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
    margin-bottom: 2rem;
    @include tablet {
        width: calc(50% - 1rem);
        &:nth-of-type(odd) {
            margin-right: 2rem;
        }
        &:last-child {
            margin-right: auto;
        }
    }
    .rich-text p {
        margin: 3rem 0 0 0;
    }
}

.m-leadership-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        @include conduit-itc-medium;
        text-transform: uppercase;
        text-align: center;
        margin: 0;
        &:first-of-type {
            margin-top: 3rem;
        }
    }
}

.m-leadership_name {
    font-size: 2.6rem;
}

.m-leadership_job {
    font-size: 2rem;
    .m-modal & {
        width: 90%;
    }
}

.m-leadership-description {
    max-width: 50rem;
}

.m-leadership-modal-btn{
    background-color: transparent;
    border: none;
    border-radius: none;
    color: $dark-grey;
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-top: 3rem;
    &:hover {
        cursor: pointer;
    }
}