.m-mobile-menu-trigger,
.m-mobile-search  {
  display:flex;
  justify-content: center;
  align-items: center;
  height:100%;
  width:14%;
  background-color:$white;
  transition: 200ms background-color ease-in-out;
  &.active {
    background-color:$brand-colour-1;
  }
  @include desktop {
    display: none;
  }
}

.m-mobile-menu-trigger {
  .m-menu-hamburger {
    height: 1.2rem;
    width: 1.4rem;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .m-mobile-menu-line {
    display: block;
    width: 1.4rem;
    height: 0.2rem;
    background-color: #5b5958;
    border-radius: 0.2rem;

  }
  &.active .m-mobile-menu-line {
    background-color:#ffffff;
  }
}

.m-mobile-search {
  .m-mobile-search-icon {
    width:1.8rem;
		height:1.7rem;
		background:url(../img/magnifiers.png) top left no-repeat;
		background-size:1.7rem 3.7rem;
  }
  &.active .m-mobile-search-icon {
    background-position: bottom left;
  }
}