form.fancy .select-menu{
    background:#EBEBEB;
    overflow:hidden;
    background: #ebebeb url('/static/img/select-form-arrow.png') center right no-repeat;
    -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

form.fancy .select-menu.input select{
   margin: -3px 0 0 7px;
   background:none;
}

form.fancy .select-menu.input select option{
   background:none;
}


form.fancy {
    width: 90%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
}

form.fancy .input {
    height: 32px;
    vertical-align: bottom;
    width: 100%;
    margin-bottom: 31px;
    padding-right: 0;
    position: relative;
}

form.fancy .input input{
    display: inline-block;
    height: 32px;
}

form.fancy .input input, form.fancy textarea, form.fancy .input select, form.fancy select option{
    width: 100%;
    background-color: #ebebeb;
    border: none;
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    padding:0;
}

form.fancy .input input, form.fancy textarea {
    text-indent: 18px;
}

form.fancy textarea {
    height: 156px;
    padding-top: 12px;
}

form.fancy .input select {
    height: 33px;
    padding: 7px 0 4px 7px;
    vertical-align: middle;
    width: 110%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
        box-sizing: border-box;

    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none;

}

form.fancy select option {

}

form.fancy button {
    width: 117px;
    height: 32px;
    background: #5D2A21;
    color: white;
    text-transform: uppercase;
    border: none;
}

div.button-shadow {
    height: 47px;
    width: 117px;
    margin: 25px 0 24px 0;
}

.contact .select-menu {
        background: #ebebeb url('/static/img/select-form-arrow.png') center right no-repeat;
}

.contact form.fancy .input input{
    line-height: 32px;
    display: inline-block;
}

form.fancy .input input::placeholder {
    color: black;
}

.input .star {
    color: #d7291e;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    left: -10px;
    top: 5px;
}

