.l-footer-wrapper {
  @include arial;
  width: 100%;
  color: #585858;
  display: flex;
  justify-content: center;
  align-items: center;
}

.m-footer {
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0.9rem;
  padding: 3rem 0;

  @include desktop {
    width: $desktop-content-width;
    justify-content: space-between;
    padding: 3rem 1.2rem;
  }
}

.m-footer-menu {
  display: flex;
  flex-wrap: wrap;
  li {
    padding: 0 0.8rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.6em;
    @include desktop {
       font-size: 1rem;
       &:first-child {
        padding-left: 0;
      }
    }

    &:not(:last-child) {
      border-right: 0.1rem solid #bfbfbf;
    }
    
  }
}

.m-footer-link {
  color: #585858;
}

.m-footer-logo {
  position: relative;
  padding: 1.5rem 0 1.5rem 0.8rem;
  @include desktop {
    padding-left: 0;
  }

  img {
    max-height: 27px;
  }
}

.m-corporate-logos-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: auto;
  max-width: 21.5rem;
  padding-top: 2rem;
  padding-left: 0.8rem;

  @include tablet-only {
      flex-direction: row;
      max-width: 50rem;
      margin: 2rem 0;
  }

  @include desktop {
    padding: 0;
    width: 30%;
  }
}

.m-corporate-logo {
  display: block;
  margin-bottom: 1.5rem;

  @include tablet-only {
     margin-right: 2rem;
  }

  @include desktop {
    margin-bottom: 1rem;
    width: 100%;
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.m-copyright {
  font-size: 1.2rem;
  margin: 0 0 0 0.8rem;

  @include desktop {
     font-size: 1rem;
     margin: 0;
  }
}
