.m-logo-and-search {
  height: 8.3rem;
  position: relative;
  display: flex;
  background: $white;
}

.m-header-search-form {
  position: absolute;
  right: 0;
  display: block;
  color: #362f30;
  width: 100%;
  overflow: hidden;
  padding: 2.2rem 0 0 0;
  top: 6.5rem;
  background: #da291e;
  z-index: -1;
  transform: translateY(-100%);
  transition: 200ms transform ease-in-out;
  @include desktop {
    z-index: 0;
    background: transparent;
    top: 0;
    width: 25rem;
    margin: 0 2.6rem 1.5rem 1.5rem;
    transform: translateY(0);
  }
  &.active {
    transform: translateY(0);
  }
}

.m-header-search-input {
  @include conduit-itc-medium;
  background: #d9d9d9;
  border: none;
  font-size: 1.6rem;
  text-transform: uppercase;
  width: calc(100% - 3rem);
  height: 3rem;
  margin: 1.5rem;
  padding: 0 4rem 0 1rem;
  text-indent: 0;
  @include desktop {
    height: auto;
    margin: 0;
    width: 100%;
    padding: 0.6rem 0 0.6rem 0.8rem;
  }

  &::placeholder {
    color: $black;
  }
}

.m-header-search-submit {
  display: block;
  position: absolute;
  top: 2.2rem;
  right: 0;
  border: 0;
  background: url(../img/chevron-red.png) center center no-repeat;
  height: 3rem;
  width: 3rem;
  margin: 1.5rem;

  @include desktop {
    background: url("../img/icon-sprite.png") 0 -23.8rem no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.9rem 1rem;
  }
}

