.m-button {
    @include conduit-itc-medium;
    background-color: $brand-colour-3;
    color: $white;
    font-size: 1.6rem;
    line-height: 1;
    display: inline-block;
    text-transform: uppercase;
    padding: 1.2rem 3rem 1.2rem 1.6rem;
    min-width: 15rem;
    text-align: left;
    transition: background-color 0.3s ease;
    border: none;

    @include desktop {
        padding: 0.7rem 3rem 0.7rem 1.6rem;
    }
    .newsroom & {
        margin-top: 2rem;
        @include desktop {
            margin-top: 5rem;
        }
    }
    .medical-information & {
        margin-bottom: 2rem;
    }


    &:hover,
    &:focus {
        background-color: $brand-colour-1;
        text-decoration: none;
    }
}

.m-button-arrow {
    @extend .m-button;
    background: $brand-colour-3 url("../img/white-chevron.png") no-repeat right center;
    // Have to seperate for IE support
    background-position-x: calc(100% - 1.3rem);
}

.m-close-button {
    cursor: pointer;
    background: #d0d0d0;
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 3rem;
    height: 3rem;
    border: 0;
    transition: 200ms background-color ease-in-out;

    &:after {
        content: "×";
        color: $white;
        font-size: 3rem;
        height: 3rem;
        line-height: 2rem;
        
    }

    &:hover,
    &.hover,
    &:focus {
        background: $brand-colour-1;
    }
}

