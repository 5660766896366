.m-two_columns {
  display: flex;
  flex-direction: column;
  padding: 3rem 0;

  @include tablet {
    padding: 3rem 3rem 0.1rem 3rem;
    flex-direction: row;
  }

  .rich-text {
    display: flex;
  }

  .m-section-title{
    width: 100%;
  }

  .research-development & {
    .m-paragraph {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      @include tablet {
        overflow: auto;
        max-height: none;
      }
      .rich-text {
        margin-bottom: 3rem;
        @include tablet {
          margin-bottom: 0;
        }
      }
    }
  } 

  p {
    font-size: 1.6rem;
  }

  img + .m-paragraph {
    margin-top: 3rem;
  }

  .m-video-playlist-item {
    max-width: 50rem;
    margin-bottom: 3rem;
    .m-video-description {
      max-width: 25rem;
    }
  }

  .m-content > &:last-child {
    padding-bottom: $gutter;
  }
}

.m-column {
  max-width: 100%;
  // Default Image Class Name from wagtails
  .img-responsive {
    width: 100%;
    height: auto;
    @include tablet {
      width: 24.4rem;
      height: 15.7rem;
    }
  }
  &.active {
    .m-paragraph {
      max-height: 100rem;
      overflow: auto;
    }
    .m-toggle-bar {
      background-color: #c1c2c3;
      &:after {
        transform: rotate(180deg) translate(0.65rem, 50%);
      }
    }
  }
}

.m-column-2 {
  @include tablet {
    margin-left: 2.5rem;
  }
}

.m-toggle-bar {
  height: 2.2rem;
  background-color: #e6e8ea;
  width: calc((#{$mobile-content-padding} * 2) + 100%);
  margin-left: -$mobile-content-padding;
  transition: 200ms background-color ease-in-out;
  position: relative;
  &:before {
    content: '';
    height: 2.2rem;
    width: 2.2rem;
    position: absolute;
    right: 0;
    background: $brand-colour-2;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transition: transform 0.3s ease-in-out;
    transform: translate(-0.65rem, -50%);
    width: 0.9rem;
    height: 0.6rem;
    background: url(../img/icon-sprite.png) -41px -418px no-repeat;
  }
  @include tablet {
    display: none;
  }
}

.m-column-title {
  margin: 1.5rem 0;
  @include tablet {
    margin: 0;
  }
}