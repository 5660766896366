.m-landing-title-wrapper {
  margin: 0 auto;
  bottom: 4.3rem;
  text-align: center;
  padding: 5rem;

  @include desktop {
    @include horizontalCentre;
    bottom: -0.7rem;
  }
}

.m-landing-title {
  color: $brand-colour-3;
  font-size: 2.5rem;
  line-height: 1;
  margin: 0;
  padding: 0;
}

.m-landing-subtitle {
  color: $brand-colour-1;
  font-size: 2.2rem;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
}


.m-page-title {
  font-size: 4rem;
  line-height:1;
  @include desktop {
    font-size: 4.8rem;
    line-height: 5.0rem;
  }
  @include mobile-only {
    .press-detail-page & {
      font-size: 3.2rem;
    }
  }
}

.m-section-title {
  font-size: 2.6rem;
  margin-bottom: 2rem;
  line-height: 1.2;
  color: #000;

  .newsroom &,
  .template-homepage & {
    text-transform: uppercase;
  }

  .m-content > & {
    padding-top: 4rem;
    & + .m-paragraph {
      padding-top: 0;
    }
  }
}

.m-section-subtitle {
  font-size: 2rem;
  line-height: 1.2;
  color: $black;
  display: inline-block;
  position: relative;

  &:after {
    content: '';
    background: url("../img/chevron.png") no-repeat;
    background-size: cover;
    width: 0.9rem;
    height: 1.5rem;
    position: absolute;
    right: -4rem;
    top: 1rem;
  }
}

.m-subtitle {
  @include arial;
  color: $body-copy;
  font-weight: bold;
  font-size: 1.6rem;
  margin-top: 3rem;

}

.m-content h2 a {
  color: $black;
}

.newsroom p {
  color: $black;
  margin: 0;
  font-size: 1.4rem;
}

.m-defintions {
  font-size: 1.2rem;
  line-height: 1.4em;
  margin: 2rem 0 2rem 0;
}

.m-text-small {
  font-size: 1.2rem;
  line-height: 1.1;
}

[class^="m-paragraph"] {
  // LINKED H2 + SINGLE DESCRIPTION LINE
  // - usage example: Grants & Donations page.
  // - unable to add classes bc of Wagtail
  p + h2 {
    margin-top: 3rem;
  }

  // H3 + paragraph text in the body content
  // - usage example: IIT with Lonsurf. Grants + Donations
  // - unable to add classes bc of Wagtail
  p + h3 {
    margin: 3rem 0 1.5rem;
  }

}

.m-text-center {
  text-align: center;
}

// 404 and 500 text
.m-error-title {
  margin-top: 6rem;
}
.m-error-text {
  margin-bottom: 6rem;
}

  // Wagtail uses `i` instead of `em`, this will style only those within the rich-text class.
  // - unable to add classes
.rich-text i {
  font-style: italic;
}