.pipeline {
  .m-content {
    @include mobile-only {
      padding: 2rem 1rem;
    }
  }
}

.m-pipeline {
  margin: 0 auto;
  padding: 4rem $mobile-content-padding;
  background: $background-colour;
  overflow-x: scroll;
  @include desktop {
    padding: 4rem 0;
  }
}

.m-pipeline-filter {
  @include flexCenter;
  @include tablet {
    justify-content: flex-end;
  }
  @include desktop {
    max-width: $desktop-content-width;
    margin: 0 auto;
  }
}

.m-pipeline-label {
  @include conduit-itc-medium;
  font-weight: bold;
  text-transform: uppercase;
  color: #303131;
}

.m-pipeline-filter-select {
  font: 1.6rem/1.8rem $arial;
  width: 25rem;
  height: 3rem;
  margin-left: $gutter;
  border: 0;
  color: #787878;
  padding: 0 1rem;
  position: relative;
  -webkit-appearance: none;
  background: #e0dfdf url("../img/filter-select.jpg") right 0.5rem center no-repeat;
  &::-ms-expand {
    display: none;
  }
}

.m-pipeline-legend {
  @extend %reset-ul;
  clear: both;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 5rem;
  @include desktop {
    padding-left: 53rem;
    max-width: $desktop-content-width;
    margin: 0 auto;
  }
}

.m-pipeline-legend-item {
  @include conduit-itc-medium;
  font-size: 1.6rem;
  line-height: 1;
  text-transform: uppercase;
  color: #303131;
  position: relative;
  width: 33%;
  padding-left: 3rem;
  margin-bottom: 2rem;
  @include desktop {
    margin-right: 6rem;
    margin-bottom: 1rem;
    width: auto;
  }

  &::before {
    content: '';
    width: 2.5rem;
    height: 1.2rem;
    display: block;
    top: 0.1rem;
    left: 0rem;
    position: absolute;
  }

  &#preclinical::before {
    background: $phase-preclinical;
  }
  &#phase1::before {
    background: $phase-phase1;
  }
  &#phase2::before {
    background: $phase-phase2;
  }
  &#phase3::before {
    background: $phase-phase3;
  }
  &#filed::before {
    background: $phase-filed;
  }
}


.m-pipeline-table {
  width: 100%;
  // background: $background-colour;

  @include desktop {
    margin: $gutter auto;
    max-width: $desktop-content-width;
    padding: $gutter 0;
  }

    th, td {
      border-bottom: 0.1rem solid #000;
      vertical-align: middle;
      padding: 1rem 0 1rem;
    }
    th{
      vertical-align: top;
    }
  
    td:last-of-type {
      padding: 1rem 1rem 1rem;
    }

    .futibatinib-row {
          border-bottom: 1px solid black;
          padding-bottom: -1px;
    }

  // NOTE: these helper classes are for the Pipeline table only as different content is displayed depending on breakpoint.
  // For all other instances please use the media query mixins.


.desktop-only {
  display: none;
  @include desktop {
    display: block;
    &.inline {
      display: inline;
    }
    &.inline-block {
      display: inline-block;
    }
  }
}

.tablet-hidden {
  @include tablet {
    display: none;
  }
}


.mobile-only {
    display: block;

    @include desktop {
      display: none;
      &.inline,
      &.inline-block {
        display: none;
      }
    }
  }
}

.mobile-hidden {
  @include mobile-only {
    display: none;
  }
}

.m-table-header {
  @include conduit-itc-medium;
  color: #303131;
  text-align: left;
  text-transform: uppercase;
  font-size: 1.8rem;
  border-bottom: 0.1rem solid #000;
  padding-bottom: 1rem;



  &:first-child {
    width: 3.7rem;
    @include tablet {
      width: 4rem;
    }
  }

  &[data-sort]{
    cursor: pointer;
  }

  .sortable{
    display: inline-block;
    padding-right: 2.5rem;
    background: url(../img/pipelinesorter.png) no-repeat right center;
    // needs to be seperate for IE support
    background-position-x: calc(100% - 1rem);
    background-position-y: -5.7rem;
    transition: all 0.3s ease-in-out;
  }

  &.ascending .sortable{
    background-position-y: -12.7rem;
  }
  &.descending .sortable{
    background-position-y: 1.3rem;
  }
}


.m-pipeline-modal-button {
  background: url("../img/pipline-modal-icon.png") no-repeat;
  background-size: contain;
  display: block;
  width: 2.3rem;
  height: 2.3rem;
  border: 0;
  @include tablet{
    width: 2.7rem;
    height: 2.7rem;
  }

}

.openrow {
  width: 3.7rem;
  @include tablet {
    width: 4rem;
  }
}

.indcode, .indication, .moa {
  font: bold 1.6rem $arial;
  @include tablet {
    font-size: 2.2rem;
  }
  @include desktop {
    width: 20%;
  }
  ol, ul{
    margin-left: 2rem;
  }
}

.moa p {
  font-size: 1.5rem;
  margin-top: 0;
}

.indcode {
  @include tablet {
    width: 20%;
  }
  @include desktop {
    width: 20%;
  }
}

.indication {
  width: 20%;
  //text-transform: capitalize;
  font-size: 1.6rem;
  vertical-align: middle;
  @include tablet {
    width: 18rem;
  }
  @include desktop {
    width: 25rem;
  }
}

.phase {
  width: 70%;
  @include tablet {
    width: auto;
  }
}


.m-pipeline-compounds {
  font: bold 1.6rem $arial;
  display: block;
  text-transform: uppercase;
  // margin-bottom: 1rem;
  @include tablet {
    font-size: 2.2rem;
  }
}

.m-pipeline-location {
  width: 2.5rem;
  height: 1.5rem;
  text-indent: -9999.9rem;
  display: inline-block;
  @include tablet {
    width: 4.6rem;
    height: 2.8rem;
  }


  &.flag {
    &-eu {
      background: url("../img/flag-eu.jpg") no-repeat;
      background-size: contain;
    }
    &-jp {
      background: url("../img/flag-japan.jpg") no-repeat;
      background-size: contain;
    }
    &-us {
      background: url("../img/flag-usa.jpg") no-repeat;
      background-size: contain;
    }
  }
}

.m-modal-data {
  .m-pipeline-location {
    width: 3.4rem;
    height: 2rem;
  }
}

.m-phases {
  @include conduit-itc-medium;
  height: 2.5rem;
  width: 8.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.6rem;
  overflow: hidden;
  position: relative;
  margin-left: 1rem;

  @include tablet {
    height: 5rem;
    text-indent: 0;
    justify-content: flex-end;
    font-size: 1.5rem;
    padding-right: 1.5rem;
    &:after {
      display: none;
    }
  }
  @include desktop {
    font-size: 2.2rem;
    padding-right: 3rem;
  }

  &.m-preclinical {
  background: $phase-preclinical;
  @include tablet {
    width: 33%;
  }
  @include desktop {
    width: 19%;
  }
}
  &.m-phase1 {
    background: $phase-phase1;
    @include tablet {
      width: 49.5%;
    }
    @include desktop {
      width: 33%;
    }
  }
  &.m-phase2 {
    background: $phase-phase2;
    @include tablet {
      width: 66%;
    }
    @include desktop {
      width: 50%;
    }
  }
  &.m-phase3 {
    background: $phase-phase3;
    @include tablet {
      width: 82.5%;
    }
    @include desktop {
      width: 67%;
    }
  }

  &.m-filed {
    background: $phase-filed;
    @include tablet {
      width: 98%;
    }
  }
}
