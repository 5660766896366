@charset "UTF-8";
/*Samsung Breakpoint*/
.m-pipeline-legend, .rich-text ul, .m-sitemap > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.m-press-release-button, .m-press-release-btn::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 0.9rem;
  height: 1.5rem;
  background-image: url("../img/icon-sprite.png");
  background-position: -25px -238px;
  background-repeat: no-repeat;
  right: 1rem;
}

.m-shadowbox-label::after, .m-feature-readmore::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: url("../img/chevron-white.png") no-repeat;
  width: 0.9rem;
  height: 1.5rem;
  right: 1rem;
}

.m-shadowbox-label::after, .m-feature-readmore::after {
  height: 2.3rem;
  width: 1.7rem;
  transform: translateY(-50%) rotate(-90deg);
  background-size: contain;
}

@font-face {
  font-family: "Conduit ITC W01 Medium";
  src: url("../fonts/a5a0edc0-b571-4275-9c51-3dfeba35b75a.eot?#iefix");
  src: url("../fonts/a5a0edc0-b571-4275-9c51-3dfeba35b75a.eot?#iefix") format("eot"), url("../fonts/a81af59b-a5d9-4e96-a7cc-919403c0d86c.woff2") format("woff2"), url("../fonts/e93ee223-5d52-4bdf-a113-c6c4c8936824.woff") format("woff"), url("../fonts/ccdadc2e-26c9-48a5-9c52-9c3cc58e9930.ttf") format("truetype");
}
@font-face {
  font-family: "Conduit ITC W01 Bold";
  src: url("../fonts/ef1cf8c3-989e-4b7e-ad89-9e034d47686d.eot?#iefix");
  src: url("../fonts/ef1cf8c3-989e-4b7e-ad89-9e034d47686d.eot?#iefix") format("eot"), url("../fonts/4680ad80-371f-497c-8926-35654adc2249.woff2") format("woff2"), url("../fonts/fdaf48d4-c023-4a03-b948-53535ee4d571.woff") format("woff"), url("../fonts/ca6dc25e-d400-4735-9fb9-ee2d025ebb5c.ttf") format("truetype");
}
/* Eric Meyer's Reset CSS v2.0 - http://cssreset.com */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*::before,
*::after {
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  line-height: 1.3;
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
  /* so that 1rem = 10px (because the standard browser font size is 16px)
     Have to use 10px and not 62.5% because IE11 was not calculating properly causing issues in layout*/
  padding: 0;
  margin: 0;
}

body {
  font-family: arial, helvetica, sans-serif;
  color: #444546;
  background: #ffffff;
  font-size: 1.6rem;
  overflow-x: hidden;
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
  main {
    position: relative;
    top: 13.5rem;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  color: #444546;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

h1 {
  color: #72392c;
  font-size: 4.8rem;
  line-height: 5rem;
  padding: 0 0 1.2rem 0;
}
h1:not(.m-homepage-hero-title) {
  text-transform: uppercase;
}

h2 {
  font-size: 2.6rem;
  line-height: 3.6rem;
  text-transform: uppercase;
}
h2 a {
  color: inherit;
}
h2 a:hover {
  text-decoration: underline;
}

h3 {
  font-size: 2rem;
  line-height: 1.2;
  color: #000000;
  display: inline-block;
  position: relative;
}
h3 a {
  color: inherit;
}
h3 a:hover {
  text-decoration: underline;
}

p {
  color: #444546;
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin: 0.75rem 0;
}

sub {
  font-size: 62.5%;
  vertical-align: text-bottom;
}

sup {
  font-size: 75%;
  vertical-align: super;
}

strong, b {
  font-weight: bold;
}

em {
  font-style: italic;
}

a {
  color: #da291e;
  text-decoration: none;
}
a:hover, a:focus, a.hover {
  text-decoration: underline;
}

ol {
  list-style: decimal;
}

.m-landing-title-wrapper {
  margin: 0 auto;
  bottom: 4.3rem;
  text-align: center;
  padding: 5rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-landing-title-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -0.7rem;
  }
}

.m-landing-title {
  color: #72392c;
  font-size: 2.5rem;
  line-height: 1;
  margin: 0;
  padding: 0;
}

.m-landing-subtitle {
  color: #da291e;
  font-size: 2.2rem;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
}

.m-page-title {
  font-size: 4rem;
  line-height: 1;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-page-title {
    font-size: 4.8rem;
    line-height: 5rem;
  }
}
@media screen and (max-width: 767px) {
  .press-detail-page .m-page-title {
    font-size: 3.2rem;
  }
}

.m-section-title {
  font-size: 2.6rem;
  margin-bottom: 2rem;
  line-height: 1.2;
  color: #000;
}
.newsroom .m-section-title, .template-homepage .m-section-title {
  text-transform: uppercase;
}
.m-content > .m-section-title {
  padding-top: 4rem;
}
.m-content > .m-section-title + .m-paragraph {
  padding-top: 0;
}

.m-section-subtitle {
  font-size: 2rem;
  line-height: 1.2;
  color: #000000;
  display: inline-block;
  position: relative;
}
.m-section-subtitle:after {
  content: "";
  background: url("../img/chevron.png") no-repeat;
  background-size: cover;
  width: 0.9rem;
  height: 1.5rem;
  position: absolute;
  right: -4rem;
  top: 1rem;
}

.m-subtitle {
  font-family: arial, helvetica, sans-serif;
  color: #444546;
  font-weight: bold;
  font-size: 1.6rem;
  margin-top: 3rem;
}

.m-content h2 a {
  color: #000000;
}

.newsroom p {
  color: #000000;
  margin: 0;
  font-size: 1.4rem;
}

.m-defintions {
  font-size: 1.2rem;
  line-height: 1.4em;
  margin: 2rem 0 2rem 0;
}

.m-text-small {
  font-size: 1.2rem;
  line-height: 1.1;
}

[class^=m-paragraph] p + h2 {
  margin-top: 3rem;
}
[class^=m-paragraph] p + h3 {
  margin: 3rem 0 1.5rem;
}

.m-text-center {
  text-align: center;
}

.m-error-title {
  margin-top: 6rem;
}

.m-error-text {
  margin-bottom: 6rem;
}

.rich-text i {
  font-style: italic;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  img {
    image-rendering: -webkit-optimize-contrast !important;
  }
}
/* Unset for Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    img {
      image-rendering: unset !important;
    }
  }
}
.l-index {
  margin: 0;
  padding: 0;
  top: 0;
}

.l-section {
  position: relative;
  overflow: hidden;
}
.l-section.is-white {
  background: #ffffff;
}

.l-flex-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 5%;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .l-flex-container {
    margin: 0;
  }
}

.m-content {
  position: relative;
  padding: 0 1.7rem;
  width: 100%;
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.3rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-content {
    margin: 0 auto;
    max-width: 136.3rem;
    padding: 0 4rem;
  }
}
.newsroom .m-content {
  padding-top: 5rem;
}
.m-search-results .m-content {
  padding-top: 4rem;
}
.m-content a {
  color: #da291e;
  word-break: break-word;
}
.m-content a.m-button, .m-content a.m-button-arrow {
  color: #ffffff;
}
.m-content a.m-news-link {
  display: inline-block;
  color: #000000;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
}
.m-content ul {
  margin: 0 0 2rem 3rem;
}
.m-content ul li {
  position: relative;
  color: #444546;
}
.m-content ul li::before {
  content: "•";
  position: absolute;
  left: -1rem;
  color: #da291e;
}

.visually-hidden {
  position: absolute !important;
  height: 0.1rem;
  width: 0.1rem;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.m-content > .m-paragraph {
  padding: 4rem 0;
}
.m-content > .m-paragraph-short {
  padding: 4rem 0;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-paragraph-short {
    width: 86.5rem;
  }
}

.is-gray {
  background: #f3f2f2;
  position: relative;
}
.is-gray::before, .is-gray::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #f3f2f2;
}
.is-gray::before {
  left: 100%;
  top: 0;
}
.is-gray::after {
  right: 100%;
  top: 0;
}
.is-gray.m-products {
  overflow: visible;
}

.is-white {
  background: #ffffff;
}

.cookies-policy, .privacy-policy, .legal-notice {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
}

.privacy-policy .m-page-title,
.legal-notice .m-page-title {
  margin-bottom: 4rem;
}

.cookies-policy main section p,
.legal-notice main section p,
.privacy-policy main section p,
.cookies-policy main section li,
.legal-notice main section li,
.privacy-policy main section li {
  font-size: 2.1rem;
}

.legal-notice main ol li {
  padding: 10px;
}

.legal-notice .m-page-title {
  display: none;
}

.legal-notice .m-section-title {
  color: #72392C;
  font-size: 4.8rem;
}

.legal-notice .m-breadcrumb-wrapper {
  display: none;
}

.legal-notice main h3,
.privacy-policy main h3 {
  text-decoration: underline;
}

.cookies-policy .m-section-title,
.privacy-policy .m-section-title {
  color: #DA291E;
  padding-top: 1rem;
}

.cookies-policy main table,
.privacy-policy main table {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-size: 1.2em;
  margin-bottom: 4rem;
}

.cookies-policy main table th,
.privacy-policy main table th {
  background: #72392c;
  color: white;
  font-weight: bold;
}

.cookies-policy main table th, .cookies-policy main table td,
.privacy-policy main table th, .privacy-policy main table td {
  padding: 10px;
  text-align: left;
}

.cookies-policy main table tbody tr:nth-child(even),
.privacy-policy main table tbody tr:nth-child(even) {
  background: #F4F4F4;
}

.cookies-policy main table tbody tr:nth-child(odd),
.privacy-policy main table tbody tr:nth-child(odd) {
  background: #D0D0D0;
}

.l-header-wrapper {
  width: 100%;
  font-size: 1.4rem;
  color: #444546;
  background: #ffffff;
  z-index: 2000;
  position: relative;
  height: auto;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .l-header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 13.9rem;
  }
}

.l-header {
  position: relative;
  width: auto;
  margin: 0 auto;
  overflow: auto;
  overflow: visible;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .l-header {
    min-width: 76.8rem;
    max-width: 136.6rem;
  }
}

.m-skip-links {
  padding: 1rem;
  position: absolute;
  top: -4.5rem;
  left: 1rem;
  z-index: 1000;
  color: transparent;
  color: #ffffff;
  background-color: #da291e;
}
.m-skip-links:focus {
  top: 1.5rem;
}

/*eyebrow nav*/
.l-eyebrow-nav {
  display: none;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .l-eyebrow-nav {
    color: #444546;
    text-align: left;
    padding-bottom: 0;
    text-transform: uppercase;
    width: 100%;
    height: 2.4rem;
    overflow: hidden;
    display: block;
  }
  .l-eyebrow-nav a {
    color: #444546;
  }
}

.m-eyebrow-nav-links {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  list-style-type: none;
  margin: 0;
  float: right;
  padding: 0.8rem 2.6rem 0 0;
  line-height: 1.5;
}
.m-eyebrow-nav-links .first,
.m-eyebrow-nav-links .last {
  display: inline;
  padding: 0 0.4rem;
  border-right: 0.1rem solid #878585;
}
.m-eyebrow-nav-links .first a:hover,
.m-eyebrow-nav-links .last a:hover {
  text-decoration: underline;
  color: #da291e;
}
.m-eyebrow-nav-links .first.last,
.m-eyebrow-nav-links .last.last {
  border: none;
}

/* main navigation */
.l-nav {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  width: 100%;
  font-size: 2.5rem;
  line-height: 3.4rem;
  max-height: 0;
  overflow: hidden;
  transition: 200ms max-height ease-in-out;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .l-nav {
    max-height: none;
    font-size: 2rem;
    overflow: visible;
  }
}
.l-nav.active {
  max-height: 100rem;
  overflow: auto;
}

.m-nav-top {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-nav-top {
    height: 3.4rem;
    display: flex;
  }
}

.m-nav-top-item {
  position: relative;
  transition: 200ms background-color ease-in-out;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-nav-top-item {
    height: 3.4rem;
    flex-grow: 1;
    flex-basis: 0;
  }
  .m-nav-top-item:hover .m-subnav, .m-nav-top-item.hover .m-subnav, .m-nav-top-item:focus .m-subnav {
    display: block;
  }
}
.m-nav-top-item.open .m-subnav {
  max-height: 50rem;
}
.m-nav-top-item.open i {
  transform: rotate(90deg);
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-nav-top-item:hover .m-nav-link, .m-nav-top-item.hover .m-nav-link, .m-nav-top-item.active .m-nav-link, .m-nav-top-item.ancestor .m-nav-link {
    background-color: #8b0406;
  }
}
.m-nav-top-item.last {
  border: none;
}

.m-nav-link {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #da291e;
  display: block;
  color: #ffffff;
  border-bottom: 0.1rem solid #FFF;
  box-shadow: 0 1rem, 1.3rem rgba(0, 0, 0, 0.3);
  z-index: 2;
  padding: 1.5rem 4rem 1.5rem 1.5rem;
  position: relative;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-nav-link {
    border-right: 0.1rem solid #ffffff;
    border-bottom: none;
    box-shadow: none;
    padding: 0 0 0 1.5rem;
  }
}
.m-nav-link:hover, .m-nav-link .hover, .m-nav-link:focus {
  text-decoration: none;
}
.m-nav-link:hover + .m-subnav, .m-nav-link .hover + .m-subnav, .m-nav-link:focus + .m-subnav {
  display: block;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .m-nav-link {
    font-size: 1.9rem;
    padding-left: 3%;
  }
}

.m-nav-link i {
  background: url("../img/white-chevron-large.png") top left no-repeat;
  background-size: cover;
  width: 1rem;
  height: 1.7rem;
  right: 1.5rem;
  top: 50%;
  margin-top: -0.9rem;
  position: absolute;
  transition: 200ms transform ease-in-out;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-nav-link i {
    display: none;
  }
}

.m-subnav {
  background: url("../img/nav-drop-bg-light.png") 0 0;
  left: 0;
  top: 0;
  transition: 200ms max-height ease-in-out;
  transition-delay: 0ms;
  z-index: -1;
  height: auto;
  overflow: hidden;
  max-height: 0;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-subnav {
    transition: 200ms transform ease-in-out;
    max-height: none;
    display: none;
    width: inherit;
  }
}
.m-subnav.active {
  transition-delay: 300ms;
}
.m-subnav.selected.forceactive a {
  color: #fbfcfc;
}

.m-subnav-item {
  background: rgba(95, 95, 95, 0.97);
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-subnav-item {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.m-subnav-link {
  color: #AAA;
  padding: 1.5rem 4rem 1.5rem 1.5rem;
  white-space: normal;
  line-height: 1;
  width: 100%;
  display: block;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-subnav-link {
    color: #ffffff;
    padding: 0.5rem 1.5rem;
    width: inherit;
  }
}
.m-subnav-link.active {
  color: #ffffff;
}
.m-subnav-link:hover, .m-subnav-link:focus, .m-subnav-link.hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.25);
}

.l-footer-wrapper {
  font-family: arial, helvetica, sans-serif;
  width: 100%;
  color: #585858;
  display: flex;
  justify-content: center;
  align-items: center;
}

.m-footer {
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0.9rem;
  padding: 3rem 0;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-footer {
    width: 130rem;
    justify-content: space-between;
    padding: 3rem 1.2rem;
  }
}

.m-footer-menu {
  display: flex;
  flex-wrap: wrap;
}
.m-footer-menu li {
  padding: 0 0.8rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.6em;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-footer-menu li {
    font-size: 1rem;
  }
  .m-footer-menu li:first-child {
    padding-left: 0;
  }
}
.m-footer-menu li:not(:last-child) {
  border-right: 0.1rem solid #bfbfbf;
}

.m-footer-link {
  color: #585858;
}

.m-footer-logo {
  position: relative;
  padding: 1.5rem 0 1.5rem 0.8rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-footer-logo {
    padding-left: 0;
  }
}
.m-footer-logo img {
  max-height: 27px;
}

.m-corporate-logos-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: auto;
  max-width: 21.5rem;
  padding-top: 2rem;
  padding-left: 0.8rem;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .m-corporate-logos-wrapper {
    flex-direction: row;
    max-width: 50rem;
    margin: 2rem 0;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-corporate-logos-wrapper {
    padding: 0;
    width: 30%;
  }
}

.m-corporate-logo {
  display: block;
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .m-corporate-logo {
    margin-right: 2rem;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-corporate-logo {
    margin-bottom: 1rem;
    width: 100%;
  }
}
.m-corporate-logo img {
  display: block;
  max-width: 100%;
  height: auto;
}

.m-copyright {
  font-size: 1.2rem;
  margin: 0 0 0 0.8rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-copyright {
    font-size: 1rem;
    margin: 0;
  }
}

.l-sitemap-wrapper {
  color: #444546;
  margin: 0 auto;
  overflow: auto;
  background: #f3f2f2;
  padding: 4rem 0;
}

.m-sitemap {
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.7rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-sitemap {
    max-width: 130rem;
  }
}
.m-sitemap > ul {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-sitemap > ul {
    flex-direction: row;
  }
}

@media screen and (max-width: 767px) {
  .m-sitemap-column {
    display: inline-block;
    width: 100%;
  }
}

.m-sitemap-header {
  padding-top: 4rem;
  font-size: 2rem;
  line-height: 1;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-sitemap-header {
    padding-top: 0;
  }
}
.m-sitemap-header a {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  color: #72392c;
  text-transform: none;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 2rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-sitemap-header a {
    margin-bottom: 1.5rem;
  }
}

.m-sitemap-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.m-sitemap-menu li {
  margin-bottom: 0.5rem;
}

.m-sitemap-link {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  color: #444546;
  font-size: 1.5rem;
  line-height: 1.2;
  text-transform: capitalize;
}
.m-sitemap-link:hover, .m-sitemap-link.hover, .m-sitemap-link:focus {
  text-decoration: underline;
}

.m-two_columns {
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
}
@media screen and (min-width: 768px) {
  .m-two_columns {
    padding: 3rem 3rem 0.1rem 3rem;
    flex-direction: row;
  }
}
.m-two_columns .rich-text {
  display: flex;
}
.m-two_columns .m-section-title {
  width: 100%;
}
.research-development .m-two_columns .m-paragraph {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}
@media screen and (min-width: 768px) {
  .research-development .m-two_columns .m-paragraph {
    overflow: auto;
    max-height: none;
  }
}
.research-development .m-two_columns .m-paragraph .rich-text {
  margin-bottom: 3rem;
}
@media screen and (min-width: 768px) {
  .research-development .m-two_columns .m-paragraph .rich-text {
    margin-bottom: 0;
  }
}
.m-two_columns p {
  font-size: 1.6rem;
}
.m-two_columns img + .m-paragraph {
  margin-top: 3rem;
}
.m-two_columns .m-video-playlist-item {
  max-width: 50rem;
  margin-bottom: 3rem;
}
.m-two_columns .m-video-playlist-item .m-video-description {
  max-width: 25rem;
}
.m-content > .m-two_columns:last-child {
  padding-bottom: 4rem;
}

.m-column {
  max-width: 100%;
}
.m-column .img-responsive {
  width: 100%;
  height: auto;
}
@media screen and (min-width: 768px) {
  .m-column .img-responsive {
    width: 24.4rem;
    height: 15.7rem;
  }
}
.m-column.active .m-paragraph {
  max-height: 100rem;
  overflow: auto;
}
.m-column.active .m-toggle-bar {
  background-color: #c1c2c3;
}
.m-column.active .m-toggle-bar:after {
  transform: rotate(180deg) translate(0.65rem, 50%);
}

@media screen and (min-width: 768px) {
  .m-column-2 {
    margin-left: 2.5rem;
  }
}

.m-toggle-bar {
  height: 2.2rem;
  background-color: #e6e8ea;
  width: calc((1.7rem * 2) + 100%);
  margin-left: -1.7rem;
  transition: 200ms background-color ease-in-out;
  position: relative;
}
.m-toggle-bar:before {
  content: "";
  height: 2.2rem;
  width: 2.2rem;
  position: absolute;
  right: 0;
  background: #ecac00;
}
.m-toggle-bar:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transition: transform 0.3s ease-in-out;
  transform: translate(-0.65rem, -50%);
  width: 0.9rem;
  height: 0.6rem;
  background: url(../img/icon-sprite.png) -41px -418px no-repeat;
}
@media screen and (min-width: 768px) {
  .m-toggle-bar {
    display: none;
  }
}

.m-column-title {
  margin: 1.5rem 0;
}
@media screen and (min-width: 768px) {
  .m-column-title {
    margin: 0;
  }
}

.m-fifty_fifty:last-of-type {
  margin-bottom: 0;
}
.m-fifty_fifty .m-column-1 {
  margin-bottom: 1rem;
}
@media screen and (min-width: 768px) {
  .m-fifty_fifty .m-column-1 {
    margin-bottom: 0;
  }
}
.m-fifty_fifty .m-two_columns {
  flex-wrap: wrap;
  padding: 4rem 0;
}
.m-fifty_fifty .m-two_columns img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.m-fifty_fifty .m-two_columns h3 {
  margin-top: 2rem;
  margin-bottom: 0;
}
.m-fifty_fifty .m-two_columns h3:first-child {
  margin-top: 0;
}
.m-fifty_fifty .m-two_columns h3 + p {
  margin: 0;
}
.m-fifty_fifty .m-two_columns p {
  margin: 2rem 0;
}
.m-fifty_fifty .m-two_columns p:first-child {
  margin-top: 0;
}
.m-fifty_fifty .m-two_columns p + h3 {
  margin: 0;
}
@media screen and (min-width: 768px) {
  .m-fifty_fifty .m-two_columns .m-column {
    width: calc(50% - 2.5rem);
  }
}

.m-breadcrumb-wrapper {
  display: inline-block;
  background: #ffffff;
  position: relative;
  z-index: 1;
  padding: 2.7rem 0 1.5rem;
}
.home .m-breadcrumb-wrapper {
  display: none;
  margin: 0 auto;
}
.terms .m-breadcrumb-wrapper {
  visibility: hidden;
}
.pipeline .m-breadcrumb-wrapper {
  padding-bottom: 0;
}

.m-breadcrumbs {
  overflow: auto;
  display: flex;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-breadcrumbs {
    padding-left: 0.4rem;
  }
}

.m-breadcrumbs-item {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  font-size: 1.4rem;
  color: #444546;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
}
.m-breadcrumbs-item:not(:first-child):before {
  content: "> ";
  color: #da291e;
  padding-left: 0.3rem;
}

.m-breadcrumbs-link {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  font-size: 1.4rem;
  color: #444546;
  text-transform: uppercase;
  text-decoration: none;
}
.m-breadcrumbs-link:hover, .m-breadcrumbs-link:focus, .m-breadcrumbs-link.hover {
  text-decoration: underline;
}

form.fancy .select-menu {
  background: #EBEBEB;
  overflow: hidden;
  background: #ebebeb url("/static/img/select-form-arrow.png") center right no-repeat;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

form.fancy .select-menu.input select {
  margin: -3px 0 0 7px;
  background: none;
}

form.fancy .select-menu.input select option {
  background: none;
}

form.fancy {
  width: 90%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}

form.fancy .input {
  height: 32px;
  vertical-align: bottom;
  width: 100%;
  margin-bottom: 31px;
  padding-right: 0;
  position: relative;
}

form.fancy .input input {
  display: inline-block;
  height: 32px;
}

form.fancy .input input, form.fancy textarea, form.fancy .input select, form.fancy select option {
  width: 100%;
  background-color: #ebebeb;
  border: none;
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  padding: 0;
}

form.fancy .input input, form.fancy textarea {
  text-indent: 18px;
}

form.fancy textarea {
  height: 156px;
  padding-top: 12px;
}

form.fancy .input select {
  height: 33px;
  padding: 7px 0 4px 7px;
  vertical-align: middle;
  width: 110%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
}

form.fancy button {
  width: 117px;
  height: 32px;
  background: #5D2A21;
  color: white;
  text-transform: uppercase;
  border: none;
}

div.button-shadow {
  height: 47px;
  width: 117px;
  margin: 25px 0 24px 0;
}

.contact .select-menu {
  background: #ebebeb url("/static/img/select-form-arrow.png") center right no-repeat;
}

.contact form.fancy .input input {
  line-height: 32px;
  display: inline-block;
}

form.fancy .input input::placeholder {
  color: black;
}

.input .star {
  color: #d7291e;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: -10px;
  top: 5px;
}

.m-button, .m-button-arrow {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  background-color: #72392c;
  color: #ffffff;
  font-size: 1.6rem;
  line-height: 1;
  display: inline-block;
  text-transform: uppercase;
  padding: 1.2rem 3rem 1.2rem 1.6rem;
  min-width: 15rem;
  text-align: left;
  transition: background-color 0.3s ease;
  border: none;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-button, .m-button-arrow {
    padding: 0.7rem 3rem 0.7rem 1.6rem;
  }
}
.newsroom .m-button, .newsroom .m-button-arrow {
  margin-top: 2rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .newsroom .m-button, .newsroom .m-button-arrow {
    margin-top: 5rem;
  }
}
.medical-information .m-button, .medical-information .m-button-arrow {
  margin-bottom: 2rem;
}
.m-button:hover, .m-button-arrow:hover, .m-button:focus, .m-button-arrow:focus {
  background-color: #da291e;
  text-decoration: none;
}

.m-button-arrow {
  background: #72392c url("../img/white-chevron.png") no-repeat right center;
  background-position-x: calc(100% - 1.3rem);
}

.m-close-button {
  cursor: pointer;
  background: #d0d0d0;
  display: block;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  border: 0;
  transition: 200ms background-color ease-in-out;
}
.m-close-button:after {
  content: "×";
  color: #ffffff;
  font-size: 3rem;
  height: 3rem;
  line-height: 2rem;
}
.m-close-button:hover, .m-close-button.hover, .m-close-button:focus {
  background: #da291e;
}

.m-feature_list {
  padding: 4rem 0;
}

.m-features-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .m-features-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 0;
  }
}

.m-feature-card {
  background: #ffffff;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .m-feature-card {
    width: 100%;
    margin-bottom: 4rem;
  }
}
@media screen and (min-width: 768px) {
  .m-feature-card {
    width: calc(33.33% - 0.7rem);
    margin-right: 1rem;
  }
  .m-feature-card:nth-of-type(3n + 3) {
    margin-right: 0;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-feature-card {
    margin-bottom: 0;
    max-width: 40rem;
    width: calc(33.33% - 3.3rem);
    margin-right: 4.9rem;
  }
  .m-feature-card:nth-of-type(3n + 3) {
    margin-right: 0;
  }
}
.m-feature-card > a {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.m-feature-card > a:hover, .m-feature-card > a.hover, .m-feature-card > a:focus {
  text-decoration: none;
}
.m-feature-card > a:hover .m-feature-readmore, .m-feature-card > a.hover .m-feature-readmore, .m-feature-card > a:focus .m-feature-readmore {
  background-color: #da291e;
}

.m-feature-link {
  position: relative;
}
.m-feature-link:hover, .m-feature-link.hover, .m-feature-link:focus {
  border: 0;
  text-decoration: none;
}
.m-feature-link:hover .m-feature-readmore, .m-feature-link.hover .m-feature-readmore, .m-feature-link:focus .m-feature-readmore {
  background: #da291e;
}

.m-feature-image-wrapper {
  width: 100%;
  height: 20rem;
  position: relative;
  overflow: hidden;
}
.m-feature-image-wrapper .m-feature-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  min-height: 100%;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .m-feature-image-wrapper .m-feature-image {
    height: 100%;
    width: auto;
    min-height: 0;
  }
}

.m-feature-text-wrapper {
  padding: 2rem;
}

.m-feature-title {
  text-transform: uppercase;
  color: #444546;
  font-size: 2.6rem;
  line-height: 1;
}

.m-feature-text {
  font-family: arial, helvetica, sans-serif;
  margin-bottom: 3rem;
  color: #000000;
  font-size: 1.6rem;
}

.m-feature-readmore {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  color: #ffffff;
  position: relative;
  background: #72392c;
  display: block;
  width: 100%;
  height: 4.5rem;
  line-height: 4.5rem;
  padding: 0 2rem;
  text-transform: uppercase;
  transition: 0.3s background-color ease;
  margin: 0;
  text-align: left;
  font-size: 1.6rem;
  border: none;
  cursor: pointer;
  justify-self: flex-end;
}
.m-hero-wrapper {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  background: #ffffff;
}

.m-hero {
  background-color: #ffffff;
  position: relative;
  max-width: 136.3rem;
  margin: 0 auto 0;
  display: flex;
  padding: 0 1.7rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-hero {
    padding: 0;
    justify-content: space-between;
  }
}
.press-releases .m-hero {
  padding-bottom: 2.5rem;
}

.m-hero-copy {
  position: relative;
  font-size: 1.6rem;
  line-height: 2.3rem;
  z-index: 20;
  width: 100%;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-hero-copy {
    width: 60%;
    margin-left: 4rem;
  }
}
.m-hero-copy.is-fullwidth {
  width: 100%;
}
.m-hero-copy p {
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 3rem;
  color: #444546;
}
.m-hero-copy h2 {
  padding: 0;
  font-size: 2.6rem;
  margin-bottom: 2rem;
  line-height: 1.2;
  color: #000000;
}

.m-hero-image {
  display: none;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-hero-image {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 51.2rem;
    margin-right: 0;
    max-width: 100%;
  }
  .m-hero-image img {
    position: relative;
    z-index: 1;
    max-width: 100%;
  }
}

.no-hero {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: #ffffff;
}

.rich-text ul {
  margin: 1.5rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .rich-text ul {
    margin: 1.5rem 0 3rem 5.5rem;
  }
}
.rich-text ul li {
  color: #444546;
  position: relative;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
}
.rich-text ul li:before {
  content: "•";
  color: #da291e;
  font-size: 2rem;
  position: absolute;
  left: -1rem;
}
.rich-text ul ul {
  margin-top: 0.5rem;
}

.template-homepage .m-hero-wrapper {
  height: 64rem;
  overflow: hidden;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .template-homepage .m-hero-wrapper {
    height: 51.3rem;
  }
}
.template-homepage .m-hero {
  background: url("../img/home-bg-mobile.jpg") no-repeat left -5rem top;
  height: 64rem;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .template-homepage .m-hero {
    background-position: left top;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .template-homepage .m-hero {
    background: none;
    height: 53rem;
    max-width: 136.6rem;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }
}

.m-homepage-hero-video {
  display: none;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-homepage-hero-video {
    display: block;
    z-index: 0;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
}

.m-homepage-hero-text-wrapper {
  z-index: 100;
  width: 25rem;
  align-self: flex-end;
  margin-top: 2rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-homepage-hero-text-wrapper {
    width: 42rem;
    margin: 5.5rem 8.2rem 0 0;
    align-self: flex-start;
  }
}

.m-homepage-hero-title {
  font-size: 5.3rem;
  line-height: 5.5rem;
  color: #000000;
  display: block;
}
.m-homepage-hero-title .m-homepage-hero-title-1 {
  font-size: 2.5rem;
  line-height: 2.5rem;
  color: #72392c;
  display: block;
}
@media screen and (max-width: 767px) {
  .m-homepage-hero-title .m-homepage-hero-title-1 {
    width: 70%;
  }
}

.m-homepage-hero-paragraph {
  color: #72392c;
  font-size: 1.4rem;
  line-height: 1.7rem;
}

.m-homepage-hero-btn {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  display: block;
  width: 19rem;
  height: 5.1rem;
  font-size: 1.9rem;
  background: url("../img/btn-learn-more.png") no-repeat;
  color: #ffffff;
  text-transform: uppercase;
  padding-top: 1.6rem;
  padding-left: 2.3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  float: right;
}

.m-logo {
  width: 30.3rem;
  margin-left: 1.7rem;
  padding-top: 0.8rem;
  flex-grow: 1;
  width: 67%;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-logo {
    flex-grow: 0;
    padding-top: 0;
    margin-left: 2rem;
  }
}
.m-logo .m-logo-img {
  max-width: 100%;
  height: auto;
  max-height: 67px;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-logo .m-logo-img {
    margin-left: 0;
  }
}

.m-news {
  background: url("../img/icon-press-release.png") no-repeat top left;
}

.m-events {
  background: url("../img/icon-calendar.png") no-repeat top left;
}

.m-button-chevron,
.m-link-chevron {
  vertical-align: text-top;
  margin-left: 0.7rem;
}

.m-modal-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleX(-1);
  z-index: -1;
}

.m-mobile-menu-trigger,
.m-mobile-search {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 14%;
  background-color: #ffffff;
  transition: 200ms background-color ease-in-out;
}
.m-mobile-menu-trigger.active,
.m-mobile-search.active {
  background-color: #da291e;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-mobile-menu-trigger,
.m-mobile-search {
    display: none;
  }
}

.m-mobile-menu-trigger .m-menu-hamburger {
  height: 1.2rem;
  width: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.m-mobile-menu-trigger .m-mobile-menu-line {
  display: block;
  width: 1.4rem;
  height: 0.2rem;
  background-color: #5b5958;
  border-radius: 0.2rem;
}
.m-mobile-menu-trigger.active .m-mobile-menu-line {
  background-color: #ffffff;
}

.m-mobile-search .m-mobile-search-icon {
  width: 1.8rem;
  height: 1.7rem;
  background: url(../img/magnifiers.png) top left no-repeat;
  background-size: 1.7rem 3.7rem;
}
.m-mobile-search.active .m-mobile-search-icon {
  background-position: bottom left;
}

.m-modal-overlay {
  display: none;
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}
.m-modal-overlay.show {
  display: block;
}

.no-scroll {
  overflow: hidden;
}

.m-modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  width: 90vw;
  max-width: 100rem;
  background-color: white;
  padding: 4rem;
  min-height: 30rem;
  max-height: calc(100% - 30px);
  overflow-y: auto;
}
.m-modal.show {
  display: block;
}
.m-modal.show.m-leadership-card-modal {
  display: flex;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-modal.is-press-release {
    width: 42rem;
    padding: 8rem 5rem;
    box-sizing: content-box;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-modal {
    min-width: 50rem;
  }
}

.m-modal-title {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  color: #da291e;
  text-align: left;
  padding: 2rem;
  font-size: 2.4rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-modal-title {
    font-size: 4.8rem;
  }
}

.m-modal-content {
  text-align: left;
  font-size: 1.4rem;
  padding: 2rem;
  background-color: #ffffff;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-modal-content {
    font-size: 1.6rem;
  }
}
.m-modal-content p {
  font: normal 1.6rem/1.2 arial, helvetica, sans-serif;
  color: #000000;
}
.m-modal-content ul {
  list-style-type: disc;
  padding-left: 2rem;
  margin-left: 0;
  color: #000000;
}
.m-modal-content ul li {
  margin-bottom: 1rem;
  color: #000000;
}
.m-modal-content ul li::before {
  display: none;
}
.m-modal-content a {
  font: bold 1.6rem/1.2 arial, helvetica, sans-serif;
  color: #da291e;
  margin-top: 4rem;
  display: inline-block;
  text-decoration: underline;
}
.m-modal-content a:hover, .m-modal-content a.hover, .m-modal-content a:focus {
  text-decoration: none;
}

.m-modal-video iframe {
  max-width: 100%;
  height: auto;
  min-height: 50.9rem;
}
@media screen and (max-width: 767px) {
  .m-modal-video iframe {
    min-height: 0;
  }
}

.m-modal-video iframe {
  max-width: 100%;
  height: auto;
  min-height: 509px;
}
@media screen and (max-width: 767px) {
  .m-modal-video iframe {
    min-height: 0;
  }
}

.m-modal-actions {
  width: 75%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.m-modal-button {
  width: 48%;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.m-modal-data {
  color: #000000;
  margin: 0;
  padding: 0;
  display: flex;
  margin-bottom: 1rem;
}
.m-modal-data dt,
.m-modal-data dd {
  margin: 0;
  padding: 0;
}
.m-modal-data dt {
  width: 12rem;
  font: bold 1.6rem arial, helvetica, sans-serif;
}
.m-modal-data dd {
  font: normal 1.6rem arial, helvetica, sans-serif;
  text-transform: capitalize;
}

.m-modal-hr {
  border-top: 0.1rem solid #8d817b;
  margin: 4rem 0;
}

[data-modal-name=release-email-response] {
  text-align: center;
}
[data-modal-name=release-email-response] .m-modal-title {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  color: #da291e;
  text-transform: uppercase;
  font-size: 5.1rem;
  line-height: 5.1rem;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
}
[data-modal-name=release-email-response] .m-modal-copy {
  text-align: center;
  margin-top: 0;
}

[data-modal-name=leadership] {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  [data-modal-name=leadership] {
    flex-direction: row;
    align-items: flex-start;
  }
  [data-modal-name=leadership] .m-leadership-info,
[data-modal-name=leadership] .m-leadership-description {
    width: 50%;
  }
}

@media screen and (min-width: 768px) {
  .m-patient-support {
    display: flex;
    justify-content: space-between;
  }
}

.m-patient-support-content h2 {
  margin-bottom: 2.5rem;
}
.m-patient-support-content a.m-button-arrow {
  margin-top: 1.5rem;
}
@media screen and (min-width: 768px) {
  .m-patient-support-content {
    padding-right: 4rem;
  }
}

.m-patient-support-image {
  margin-top: 2.5rem;
  display: flex;
  align-items: flex-end;
}
@media screen and (min-width: 768px) {
  .m-patient-support-image {
    margin-top: 0;
  }
}

.m-quote {
  height: 100%;
  display: flex;
  align-items: center;
}
.m-quote .m-quote-text {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: italic;
  font-size: 2.1rem;
}
.m-quote .m-quote-author_title {
  font-size: 1.4rem;
}

.m-quotes {
  display: inline-block;
  vertical-align: middle;
}
@media screen and (min-width: 768px) {
  .m-quotes {
    max-width: 90%;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-quotes {
    max-width: 75%;
  }
}

.m-quote-author {
  font-family: "Conduit ITC W01 Bold", Arial, sans-serif;
  font-style: normal;
  text-transform: uppercase;
  display: block;
  font-size: 1.8rem;
}

.m-quote-author_title {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  text-transform: capitalize;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .m-column-1 .m-quote {
    justify-content: flex-end;
  }
  .m-column-1 .m-quote-text,
.m-column-1 .m-quote-author,
.m-column-1 .m-quote-author_title {
    text-align: right;
  }
}

.m-logo-and-search {
  height: 8.3rem;
  position: relative;
  display: flex;
  background: #ffffff;
}

.m-header-search-form {
  position: absolute;
  right: 0;
  display: block;
  color: #362f30;
  width: 100%;
  overflow: hidden;
  padding: 2.2rem 0 0 0;
  top: 6.5rem;
  background: #da291e;
  z-index: -1;
  transform: translateY(-100%);
  transition: 200ms transform ease-in-out;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-header-search-form {
    z-index: 0;
    background: transparent;
    top: 0;
    width: 25rem;
    margin: 0 2.6rem 1.5rem 1.5rem;
    transform: translateY(0);
  }
}
.m-header-search-form.active {
  transform: translateY(0);
}

.m-header-search-input {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  background: #d9d9d9;
  border: none;
  font-size: 1.6rem;
  text-transform: uppercase;
  width: calc(100% - 3rem);
  height: 3rem;
  margin: 1.5rem;
  padding: 0 4rem 0 1rem;
  text-indent: 0;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-header-search-input {
    height: auto;
    margin: 0;
    width: 100%;
    padding: 0.6rem 0 0.6rem 0.8rem;
  }
}
.m-header-search-input::placeholder {
  color: #000000;
}

.m-header-search-submit {
  display: block;
  position: absolute;
  top: 2.2rem;
  right: 0;
  border: 0;
  background: url(../img/chevron-red.png) center center no-repeat;
  height: 3rem;
  width: 3rem;
  margin: 1.5rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-header-search-submit {
    background: url("../img/icon-sprite.png") 0 -23.8rem no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.9rem 1rem;
  }
}

.m-shadowbox {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-top: 4rem;
  height: auto;
}
@media screen and (min-width: 768px) {
  .m-shadowbox {
    height: 21rem;
    flex-direction: row;
  }
}

.m-shadowbox-link {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  display: block;
  color: #ffffff;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  padding: 3rem 1.5rem 0rem 1.5rem;
  position: relative;
}
.m-shadowbox-link:hover {
  cursor: pointer;
}
.m-shadowbox-link:hover .m-shadowbox-label {
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .m-shadowbox-link {
    padding: 0;
    width: 26rem;
    height: 17rem;
  }
}
.m-shadowbox-link::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1.9rem;
  width: 2.9rem;
  background: url(../img/video-player/playbutton.png) no-repeat;
  background-size: contain;
}

.m-shadowbox-thumbnail {
  max-width: 100%;
  width: 100%;
  height: auto;
}
@media screen and (min-width: 768px) {
  .m-shadowbox-thumbnail {
    width: auto;
  }
}

.m-shadowbox-label {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  color: #ffffff;
  margin: 0;
  text-transform: uppercase;
  font-size: 1.6rem;
  background: #72392c;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .m-shadowbox-label {
    padding: 0.1rem 0 0.1rem 1rem;
    position: relative;
  }
}
@media screen and (min-width: 768px) {
  .m-shadowbox-label.mobile-only {
    display: none;
  }
}
.m-shadowbox-label.desktop-only {
  display: none;
}
@media screen and (min-width: 768px) {
  .m-shadowbox-label.desktop-only {
    display: block;
  }
}
.m-shadowbox-label:hover {
  cursor: pointer;
}

.m-shadowbox img.shadow {
  width: 100%;
  height: 1.8rem;
}

.m-shadowbox-data {
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1.5rem 1.5rem 5.5rem 1.5rem;
}
@media screen and (min-width: 768px) {
  .m-shadowbox-data {
    width: calc(100% - 25rem);
    padding: 0 2rem;
  }
}

.m-shadowbox-title {
  color: #000000;
  margin-top: 1rem;
}
@media screen and (min-width: 768px) {
  .m-shadowbox-title {
    margin-top: 0;
  }
}

.m-shadowbox p:not(.m-shadowbox-label) {
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin: 0;
  color: #444546;
}

.m-video-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 4rem 0;
}
.m-video-wrapper .m-yt-embed {
  display: block;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .m-video-wrapper .m-yt-embed {
    height: auto;
    min-height: 19.2rem;
  }
}
.m-video-wrapper .m-section-title {
  width: 100%;
}

.m-video-container {
  width: 100%;
  min-height: 19.2rem;
}
@media screen and (min-width: 768px) {
  .m-video-container {
    width: 72.6rem;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-video-container {
    width: calc(100% - 53.4rem);
  }
}

.m-video-playlist {
  max-height: 42rem;
  width: 100%;
  margin: 1.5rem 0;
}
@media screen and (min-width: 768px) {
  .m-video-playlist {
    width: 72.6rem;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-video-playlist {
    margin: 0;
    padding: 0 1.5rem;
    max-width: 53.4rem;
  }
}

.simplebar-track {
  right: -1.5rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .simplebar-track {
    right: 0;
  }
}

.m-video-playlist-item {
  cursor: pointer;
  min-width: 30rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  border: 0.2rem solid #e9e9e9;
  background-color: #ffffff;
}
.m-video-playlist-item.active {
  border-color: #da291e;
}
.m-video-playlist-item:hover .m-video-playlist-item.hover .m-video-thumbnail::after {
  background: url("../img/video-player/playbuttonhover.png") no-repeat;
  background-size: contain;
}

.m-video-thumbnail {
  width: 20rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .m-video-thumbnail {
    width: 26rem;
  }
}
.m-video-thumbnail::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1.9rem;
  width: 2.9rem;
  background: url("../img/video-player/playbutton.png") no-repeat;
  background-size: contain;
}

.m-thumbnail-img {
  display: block;
  width: 100%;
}

.m-video-length {
  position: absolute;
  font-size: 1.2rem;
  line-height: 1.2rem;
  bottom: 0;
  right: 0;
  display: block;
  padding: 0.8rem 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
}
.m-shadow_box .m-video-length {
  bottom: 0.5rem;
  right: 2rem;
}
@media screen and (min-width: 768px) {
  .m-shadow_box .m-video-length {
    bottom: 3rem;
    right: 0.5rem;
  }
}

.m-video-description {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  padding: 1rem;
  width: 17.5rem;
}
@media screen and (min-width: 768px) {
  .m-video-description {
    padding: 2rem 0 0 2.5rem;
  }
}
.m-video-description p, .m-video-description h3 {
  margin: 0;
  font-size: 2rem;
  color: #444546;
}
@media screen and (max-width: 360px) {
  .m-video-description p, .m-video-description h3 {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 768px) {
  .m-video-description p, .m-video-description h3 {
    font-size: 2.2rem;
  }
}

[class^=m-flag] {
  position: absolute;
  display: block;
}
[class^=m-flag].hide {
  visibility: hidden;
}

.m-flag-us {
  background: url("../img/map/flag-usa.png") no-repeat;
  background-size: cover;
  width: 2.6rem;
  height: 1.7rem;
}

.m-flag-jp {
  background: url("../img/map/flag-japan.png") no-repeat;
  background-size: cover;
  width: 2.7rem;
  height: 1.8rem;
}

.m-flag-ca {
  background: url("../img/map/flag-canada.png") no-repeat;
  background-size: cover;
  width: 2.6rem;
  height: 1.7rem;
}

.m-flag-gb {
  background: url("../img/map/flag-uk.png") no-repeat;
  background-size: cover;
  width: 2.6rem;
  height: 1.7rem;
}

.m-flag-sg {
  background: url("../img/map/flag-singapore.png") no-repeat;
  background-size: cover;
  width: 2.7rem;
  height: 1.8rem;
}

.m-flag-cn {
  background: url("../img/map/flag-china.png") no-repeat;
  background-size: cover;
  width: 2.6rem;
  height: 1.7rem;
}

.m-flag-eu {
  background: url("../img/map/flag-eu.png") no-repeat;
  background-size: cover;
  width: 2.6rem;
  height: 1.7rem;
}

.m-flag-ch {
  background: url("../img/map/flag-switzerland.png") no-repeat;
  background-size: cover;
  width: 2.6rem;
  height: 1.7rem;
}

.l-map-wrapper {
  background: #f3f2f2;
  width: 100%;
  height: auto;
  position: relative;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .l-map-wrapper {
    background-image: url("../img/map/map-canada.png"), url("../img/map/map-usa.png"), url("../img/map/map-uk.png"), url("../img/map/map-china.png"), url("../img/map/map-japan.png"), url("../img/map/map-eu.png"), url("../img/map/map-switzerland.png");
    background-repeat: no-repeat;
    background-position: -100%;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    height: auto;
  }
}

.m-map {
  display: none;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-map {
    background: #f3f2f2 url("../img/map/map-bg.png") no-repeat top 5rem center;
    width: 102.4rem;
    height: 60rem;
    position: relative;
    margin: 0 auto;
    display: block;
  }
}

.m-country-wrapper {
  position: absolute;
}
.m-country-wrapper[data-country=CA] {
  width: 20.7rem;
  height: 17.1rem;
  left: 15.6rem;
  top: 8.7rem;
  z-index: 1;
}
.m-country-wrapper[data-country=US] {
  width: 23.7rem;
  height: 15.5rem;
  left: 9.3rem;
  top: 15.2rem;
}
.m-country-wrapper[data-country=GB] {
  width: 2.3rem;
  height: 3.1rem;
  left: 46.8rem;
  top: 20.2rem;
}
.m-country-wrapper[data-country=CN] {
  width: 14rem;
  height: 10.3rem;
  left: 65.8rem;
  top: 22.2rem;
}
.m-country-wrapper[data-country=JP] {
  width: 4rem;
  height: 4.2rem;
  left: 78.5rem;
  top: 24.8rem;
}
.m-country-wrapper[data-country=SG] {
  width: 5rem;
  height: 5rem;
  left: 71.8rem;
  top: 34.2rem;
}
.m-country-wrapper[data-country=EU] {
  width: 4.6rem;
  height: 3rem;
  left: 50rem;
  top: 19rem;
}
.m-country-wrapper[data-country=CH] {
  width: 4rem;
  height: 3rem;
  left: 50.5rem;
  top: 23.6rem;
}

.m-country-trigger-area {
  color: #000000;
  font-size: 4rem;
  position: absolute;
  width: 3rem;
  height: 3rem;
}
.m-country-trigger-area:hover, .m-country-trigger-area.hover, .m-country-trigger-area:focus {
  text-decoration: none;
  color: #da291e;
}
.m-country-trigger-area:hover [class^=m-pip], .m-country-trigger-area.hover [class^=m-pip], .m-country-trigger-area:focus [class^=m-pip] {
  z-index: 1;
}
.m-country-trigger-area:hover span[class^=m-pip]::before, .m-country-trigger-area.hover span[class^=m-pip]::before, .m-country-trigger-area:focus span[class^=m-pip]::before {
  content: "";
  position: absolute;
  height: calc(100% + 2px);
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 100%;
  z-index: -1;
}
[data-country=CA] .m-country-trigger-area {
  left: 11.1rem;
  top: 13.8rem;
  width: 4rem;
}
[data-country=US] .m-country-trigger-area.is-nj {
  right: -1.5rem;
  top: 10.9rem;
  width: 4rem;
}
[data-country=US] .m-country-trigger-area.is-nj:hover, [data-country=US] .m-country-trigger-area.is-nj:focus, [data-country=US] .m-country-trigger-area.is-nj.hover {
  z-index: 99;
}
[data-country=US] .m-country-trigger-area.is-ca {
  top: 10.8rem;
  left: 7rem;
  width: 4rem;
}
[data-country=US] .m-country-trigger-area.is-ca .m-pip-us {
  right: 0;
}
[data-country=GB] .m-country-trigger-area {
  left: -1.7rem;
  top: 1.5rem;
  width: 4rem;
}
[data-country=CN] .m-country-trigger-area {
  left: 6.5rem;
  top: 1.6rem;
  width: 3.5rem;
}
[data-country=CN] .m-country-trigger-area:hover, [data-country=CN] .m-country-trigger-area:focus, [data-country=CN] .m-country-trigger-area.hover {
  z-index: 99;
}
[data-country=JP] .m-country-trigger-area {
  left: -0.3rem;
  top: 2.4rem;
  width: 6rem;
}
[data-country=SG] .m-country-trigger-area {
  bottom: 0;
  width: 4rem;
}
[data-country=EU] .m-country-trigger-area {
  left: -0.2rem;
  width: 4.6rem;
  height: 2.5rem;
}
[data-country=CH] .m-country-trigger-area {
  bottom: 0;
  width: 4rem;
  left: 0.25rem;
}
.m-country-trigger-area [class^=m-pip] {
  position: absolute;
  font-size: 3.5rem;
  line-height: 1rem;
  display: block;
}
.m-country-trigger-area .m-pip-ca {
  right: 0;
  bottom: 0;
}
.m-country-trigger-area .m-pip-gb {
  right: 0.2rem;
  top: 0.2rem;
}
.m-country-trigger-area .m-pip-cn {
  bottom: -0.3rem;
  right: -0.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
}
.m-country-trigger-area .m-pip-jp {
  top: 0.1rem;
  left: 2.1rem;
}
.m-country-trigger-area .m-pip-sg {
  top: 0.1rem;
}
.m-country-trigger-area .m-pip-eu {
  top: 1.3rem;
  left: 0.7rem;
}
.m-country-trigger-area .m-pip-ch {
  top: 0.1rem;
  z-index: 1;
}
.m-country-trigger-area .m-flag-ca {
  left: 0.2rem;
  top: 0.2rem;
}
.m-country-trigger-area .m-flag-gb {
  top: 1.3rem;
  left: 0;
}
.m-country-trigger-area .m-flag-cn {
  left: 0;
  top: 0.3rem;
}
.m-country-trigger-area .m-flag-jp {
  top: 1rem;
  left: 3.2rem;
}
.m-country-trigger-area .m-flag-sg {
  top: 1.2rem;
  left: 1.3rem;
}
.m-country-trigger-area .m-flag-eu {
  left: 2.3rem;
}
.m-country-trigger-area .m-flag-ch {
  top: 1.2rem;
  left: 1.3rem;
  z-index: 1;
}
.m-country-trigger-area.is-nj .m-flag-us {
  left: 1.2rem;
  top: 1rem;
}
.m-country-trigger-area.is-ca .m-flag-us {
  left: 0.2rem;
  top: 1.2rem;
}

.m-country-map {
  position: absolute;
  display: none;
  left: 0;
  top: 0;
}
[data-country=CA] .m-country-map.current {
  width: 20.7rem;
  height: 17.1rem;
  background: url("../img/map/map-canada.png") no-repeat;
  display: block;
}
[data-country=US] .m-country-map.current {
  width: 23.7rem;
  height: 15.5rem;
  background: url("../img/map/map-usa.png") no-repeat;
  display: block;
}
[data-country=GB] .m-country-map.current {
  width: 2.3rem;
  height: 3.1rem;
  background: url("../img/map/map-uk.png") no-repeat;
  display: block;
}
[data-country=CN] .m-country-map.current {
  width: 14rem;
  height: 10.3rem;
  background: url("../img/map/map-china.png") no-repeat;
  display: block;
}
[data-country=JP] .m-country-map.current {
  width: 4rem;
  height: 4.2rem;
  background: url("../img/map/map-japan.png") no-repeat;
  display: block;
}
[data-country=EU] .m-country-map.current {
  width: 4.6rem;
  height: 3rem;
  background: url("../img/map/map-eu.png") no-repeat;
  display: block;
  left: -3.85rem;
  top: -3.6rem;
  height: 13rem;
  width: 10rem;
}
[data-country=CH] .m-country-map.current {
  width: 4rem;
  height: 3rem;
  background: url("../img/map/map-switzerland.png") no-repeat;
  display: block;
  left: -0.4rem;
  top: 0.3rem;
}

.l-map-locations {
  padding: 2rem;
  margin: 5rem auto;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .l-map-locations {
    width: 102.4rem;
    height: 103rem;
    flex-direction: column;
    padding: 0;
  }
}

.m-address {
  width: 100%;
  margin-bottom: 3rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-address {
    width: 50%;
    min-height: 18rem;
    margin-bottom: 0;
  }
}
.m-address [class^=m-flag] {
  position: relative;
  display: block;
}
@media screen and (max-width: 767px) {
  .m-address [class^=m-flag] {
    margin-bottom: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .m-address [class^=m-flag] {
    display: inline-block;
  }
}

.m-address-title {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .m-address-title {
    line-height: 1;
  }
}
@media screen and (min-width: 768px) {
  .m-address-title {
    display: inline-block;
  }
}

.m-address-text {
  margin: 0;
  font-family: arial, helvetica, sans-serif;
  margin-right: 1.5rem;
}
@media screen and (min-width: 768px) {
  .m-address-text {
    margin-left: 3.2rem;
  }
}

.m-tel-link {
  text-decoration: none;
  color: #444546;
}

.m-location-website {
  text-decoration: underline;
}
@media screen and (min-width: 768px) {
  .m-location-website {
    margin-left: 3rem;
  }
}
.m-location-website:hover, .m-location-website.hover, .m-location-website:focus {
  text-decoration: none;
}

.m-info-box {
  width: 31rem;
  height: auto;
  background: #ffffff;
  border: 0.2rem solid #82807c;
  position: absolute;
  display: none;
  z-index: 10;
  box-shadow: 0.3rem 0.3rem 0.5rem -0.2rem rgba(0, 0, 0, 0.25);
  padding: 0.5rem 1rem;
}
[data-country=CA] .m-info-box {
  top: 3rem;
  left: 15rem;
  width: 32rem;
}
[data-country=GB] .m-info-box {
  left: 2rem;
  top: -10rem;
  width: 30rem;
}
[data-country=CN] .m-info-box {
  top: -9.6rem;
  left: -28.9rem;
  width: 38rem;
}
[data-country=JP] .m-info-box {
  top: 3.5rem;
  left: -30.2rem;
  width: 32rem;
}
[data-country=SG] .m-info-box {
  left: -34.9rem;
  top: 3.2rem;
  width: 35rem;
}
[data-country=EU] .m-info-box {
  top: -8.5rem;
  left: 5.2rem;
  width: 32rem;
}
[data-country=CH] .m-info-box {
  left: 3.5rem;
  top: 3.2rem;
  width: 35rem;
}
.m-info-box.show {
  display: block;
}
.m-info-box.show > span {
  visibility: visible;
}
.m-info-box .m-address-title {
  font-family: "Conduit ITC W01 Bold", Arial, sans-serif;
  font-style: normal;
  font-size: 1.8rem;
  margin-left: 0.5rem;
  line-height: 2.2rem;
}
.m-info-box .m-address-text {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 3.5rem;
}
.m-info-box .m-web-address-text {
  margin-top: 1rem;
}
.m-info-box [class^=m-flag] {
  position: relative;
  display: inline-block;
}

.is-nj + .m-info-box {
  top: 12rem;
  left: 22.4rem;
}

.is-ca + .m-info-box {
  top: 12rem;
  left: 11rem;
  width: 28rem;
}

.index-map .l-footer-wrapper {
  width: 100%;
  margin: 2rem auto;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 2rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .index-map .l-footer-wrapper {
    width: 102.4rem;
    padding: 0;
  }
}
.index-map .l-footer-wrapper .m-copyright {
  margin: 0;
}
.index-map .l-footer-wrapper .m-corporate-logo {
  width: 20rem;
}
.index-map .l-footer-wrapper .m-corporate-logo img {
  max-width: 100%;
  height: auto;
}
.index-map .l-footer-wrapper .m-footer-menu {
  margin-top: 2rem;
}
.index-map .l-footer-wrapper .m-footer-menu li:first-child {
  padding-left: 0;
}

.m-recent-content-block-wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-recent-content-block-wrapper {
    flex-direction: row;
  }
}

.m-recent-content-block {
  padding: 4rem 0 8rem;
  position: relative;
  margin-bottom: 4rem;
}
@media screen and (min-width: 768px) {
  .m-recent-content-block {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-recent-content-block {
    margin-bottom: 0;
    max-width: 40rem;
    width: calc(33.33% - 3.3rem);
    margin-right: 4.9rem;
  }
  .m-recent-content-block:nth-of-type(3n + 3) {
    margin-right: 0;
  }
}
.m-recent-content-block .m-button-arrow {
  position: absolute;
  bottom: 4rem;
}

.m-recent-content-title {
  text-transform: uppercase;
  font-size: 2.6rem;
  color: #8d817b;
  padding-top: 0.8rem;
  padding-left: 6rem;
  padding-bottom: 2.6rem;
}
.m-recent-content-title.is-press-releases {
  background: url("../img/icon-press-release.png") no-repeat left top;
}
.m-recent-content-title.is-social-responsibility {
  background: url("../img/icon-corporate-responsibility.png") no-repeat left top;
}
.m-recent-content-title.is-job-postings {
  background: url("../img/icon-job-postings.png") no-repeat left top;
}

.m-content .m-recent-content-link,
.m-content .m-recent-content-text {
  font-size: 1.4rem;
  color: #000000;
  margin-top: 0;
}

.m-content .m-recent-content-list {
  margin: 0;
}

.m-recent-content-list-item {
  margin-bottom: 2rem;
}
.m-recent-content-list-item:before {
  display: none;
}

.m-product_block .m-products {
  padding-top: 4rem;
}

.m-product {
  margin: 0 auto;
  padding: 2.5rem 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
@media screen and (min-width: 768px) {
  .m-product {
    width: 100%;
    padding: 4rem 0;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-product {
    width: 85rem;
    justify-content: space-between;
    border-bottom: 0.1rem solid #d9d9d9;
  }
}
.m-product:last-child {
  border-bottom: none;
}
.m-product p {
  margin-bottom: 2.5rem;
}
.m-product p:last-child {
  margin-bottom: 0;
}
.m-product::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -1.7rem;
  height: 0.1rem;
  width: calc((1.7rem * 2) + 100%);
  background-color: #d9d9d9;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-product::after {
    display: none;
  }
}

.m-product-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem;
  margin: 2rem 0;
}
@media screen and (min-width: 768px) {
  .m-product-image {
    width: 28%;
    padding-right: 3rem;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-product-image {
    width: 22rem;
    padding: 0;
  }
}
.m-product-image .img-responsive {
  width: 67%;
  height: auto;
  display: block;
}
@media screen and (min-width: 768px) {
  .m-product-image .img-responsive {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
  }
}

.m-product-content {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .m-product-content {
    width: 72%;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-product-content {
    width: 65%;
  }
}

.m-product-links {
  text-align: left;
}
@media screen and (max-width: 767px) {
  .m-product-links .m-button-arrow {
    margin: 4rem 0;
  }
}
.m-product-links a {
  display: block;
  margin-bottom: 1rem;
  text-align: left;
  margin-top: 2rem;
}
@media screen and (min-width: 768px) {
  .m-product-links a {
    text-align: center;
    display: inline;
    margin: 3rem 1rem 0 0;
  }
  .m-product-links a:not(.m-button-arrow) {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 767px) {
  .m-product-links .m-text-link {
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 768px) {
  .m-product-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }
}

.about .m-products {
  background-color: #ffffff;
}
.about .m-products .m-product-image {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .pipeline .m-content {
    padding: 2rem 1rem;
  }
}

.m-pipeline {
  margin: 0 auto;
  padding: 4rem 1.7rem;
  background: #f3f2f2;
  overflow-x: scroll;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-pipeline {
    padding: 4rem 0;
  }
}

.m-pipeline-filter {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .m-pipeline-filter {
    justify-content: flex-end;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-pipeline-filter {
    max-width: 130rem;
    margin: 0 auto;
  }
}

.m-pipeline-label {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  color: #303131;
}

.m-pipeline-filter-select {
  font: 1.6rem/1.8rem arial, helvetica, sans-serif;
  width: 25rem;
  height: 3rem;
  margin-left: 4rem;
  border: 0;
  color: #787878;
  padding: 0 1rem;
  position: relative;
  -webkit-appearance: none;
  background: #e0dfdf url("../img/filter-select.jpg") right 0.5rem center no-repeat;
}
.m-pipeline-filter-select::-ms-expand {
  display: none;
}

.m-pipeline-legend {
  clear: both;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 5rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-pipeline-legend {
    padding-left: 53rem;
    max-width: 130rem;
    margin: 0 auto;
  }
}

.m-pipeline-legend-item {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1;
  text-transform: uppercase;
  color: #303131;
  position: relative;
  width: 33%;
  padding-left: 3rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-pipeline-legend-item {
    margin-right: 6rem;
    margin-bottom: 1rem;
    width: auto;
  }
}
.m-pipeline-legend-item::before {
  content: "";
  width: 2.5rem;
  height: 1.2rem;
  display: block;
  top: 0.1rem;
  left: 0rem;
  position: absolute;
}
.m-pipeline-legend-item#preclinical::before {
  background: #8d817b;
}
.m-pipeline-legend-item#phase1::before {
  background: #72392c;
}
.m-pipeline-legend-item#phase2::before {
  background: #ecac00;
}
.m-pipeline-legend-item#phase3::before {
  background: #da291e;
}
.m-pipeline-legend-item#filed::before {
  background: #00a29b;
}

.m-pipeline-table {
  width: 100%;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-pipeline-table {
    margin: 4rem auto;
    max-width: 130rem;
    padding: 4rem 0;
  }
}
.m-pipeline-table th, .m-pipeline-table td {
  border-bottom: 0.1rem solid #000;
  vertical-align: middle;
  padding: 1rem 0 1rem;
}
.m-pipeline-table th {
  vertical-align: top;
}
.m-pipeline-table td:last-of-type {
  padding: 1rem 1rem 1rem;
}
.m-pipeline-table .futibatinib-row {
  border-bottom: 1px solid black;
  padding-bottom: -1px;
}
.m-pipeline-table .desktop-only {
  display: none;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-pipeline-table .desktop-only {
    display: block;
  }
  .m-pipeline-table .desktop-only.inline {
    display: inline;
  }
  .m-pipeline-table .desktop-only.inline-block {
    display: inline-block;
  }
}
@media screen and (min-width: 768px) {
  .m-pipeline-table .tablet-hidden {
    display: none;
  }
}
.m-pipeline-table .mobile-only {
  display: block;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-pipeline-table .mobile-only {
    display: none;
  }
  .m-pipeline-table .mobile-only.inline, .m-pipeline-table .mobile-only.inline-block {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .mobile-hidden {
    display: none;
  }
}

.m-table-header {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  color: #303131;
  text-align: left;
  text-transform: uppercase;
  font-size: 1.8rem;
  border-bottom: 0.1rem solid #000;
  padding-bottom: 1rem;
}
.m-table-header:first-child {
  width: 3.7rem;
}
@media screen and (min-width: 768px) {
  .m-table-header:first-child {
    width: 4rem;
  }
}
.m-table-header[data-sort] {
  cursor: pointer;
}
.m-table-header .sortable {
  display: inline-block;
  padding-right: 2.5rem;
  background: url(../img/pipelinesorter.png) no-repeat right center;
  background-position-x: calc(100% - 1rem);
  background-position-y: -5.7rem;
  transition: all 0.3s ease-in-out;
}
.m-table-header.ascending .sortable {
  background-position-y: -12.7rem;
}
.m-table-header.descending .sortable {
  background-position-y: 1.3rem;
}

.m-pipeline-modal-button {
  background: url("../img/pipline-modal-icon.png") no-repeat;
  background-size: contain;
  display: block;
  width: 2.3rem;
  height: 2.3rem;
  border: 0;
}
@media screen and (min-width: 768px) {
  .m-pipeline-modal-button {
    width: 2.7rem;
    height: 2.7rem;
  }
}

.openrow {
  width: 3.7rem;
}
@media screen and (min-width: 768px) {
  .openrow {
    width: 4rem;
  }
}

.indcode, .indication, .moa {
  font: bold 1.6rem arial, helvetica, sans-serif;
}
@media screen and (min-width: 768px) {
  .indcode, .indication, .moa {
    font-size: 2.2rem;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .indcode, .indication, .moa {
    width: 20%;
  }
}
.indcode ol, .indcode ul, .indication ol, .indication ul, .moa ol, .moa ul {
  margin-left: 2rem;
}

.moa p {
  font-size: 1.5rem;
  margin-top: 0;
}

@media screen and (min-width: 768px) {
  .indcode {
    width: 20%;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .indcode {
    width: 20%;
  }
}

.indication {
  width: 20%;
  font-size: 1.6rem;
  vertical-align: middle;
}
@media screen and (min-width: 768px) {
  .indication {
    width: 18rem;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .indication {
    width: 25rem;
  }
}

.phase {
  width: 70%;
}
@media screen and (min-width: 768px) {
  .phase {
    width: auto;
  }
}

.m-pipeline-compounds {
  font: bold 1.6rem arial, helvetica, sans-serif;
  display: block;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .m-pipeline-compounds {
    font-size: 2.2rem;
  }
}

.m-pipeline-location {
  width: 2.5rem;
  height: 1.5rem;
  text-indent: -9999.9rem;
  display: inline-block;
}
@media screen and (min-width: 768px) {
  .m-pipeline-location {
    width: 4.6rem;
    height: 2.8rem;
  }
}
.m-pipeline-location.flag-eu {
  background: url("../img/flag-eu.jpg") no-repeat;
  background-size: contain;
}
.m-pipeline-location.flag-jp {
  background: url("../img/flag-japan.jpg") no-repeat;
  background-size: contain;
}
.m-pipeline-location.flag-us {
  background: url("../img/flag-usa.jpg") no-repeat;
  background-size: contain;
}

.m-modal-data .m-pipeline-location {
  width: 3.4rem;
  height: 2rem;
}

.m-phases {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  height: 2.5rem;
  width: 8.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.6rem;
  overflow: hidden;
  position: relative;
  margin-left: 1rem;
}
@media screen and (min-width: 768px) {
  .m-phases {
    height: 5rem;
    text-indent: 0;
    justify-content: flex-end;
    font-size: 1.5rem;
    padding-right: 1.5rem;
  }
  .m-phases:after {
    display: none;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-phases {
    font-size: 2.2rem;
    padding-right: 3rem;
  }
}
.m-phases.m-preclinical {
  background: #8d817b;
}
@media screen and (min-width: 768px) {
  .m-phases.m-preclinical {
    width: 33%;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-phases.m-preclinical {
    width: 19%;
  }
}
.m-phases.m-phase1 {
  background: #72392c;
}
@media screen and (min-width: 768px) {
  .m-phases.m-phase1 {
    width: 49.5%;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-phases.m-phase1 {
    width: 33%;
  }
}
.m-phases.m-phase2 {
  background: #ecac00;
}
@media screen and (min-width: 768px) {
  .m-phases.m-phase2 {
    width: 66%;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-phases.m-phase2 {
    width: 50%;
  }
}
.m-phases.m-phase3 {
  background: #da291e;
}
@media screen and (min-width: 768px) {
  .m-phases.m-phase3 {
    width: 82.5%;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-phases.m-phase3 {
    width: 67%;
  }
}
.m-phases.m-filed {
  background: #00a29b;
}
@media screen and (min-width: 768px) {
  .m-phases.m-filed {
    width: 98%;
  }
}

.m-press-releases {
  background: #f3f2f2;
  padding: 4rem 0;
}

.m-press-release-date {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  font-size: 1.9rem;
  margin-bottom: 3rem;
}

.m-press-release-count {
  font-weight: bold;
  font-size: 2rem;
  padding: 2rem 0;
}

.m-press-release-filter {
  font-family: arial, helvetica, sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
@media screen and (min-width: 768px) {
  .m-press-release-filter {
    flex-direction: row;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-press-release-filter {
    max-width: 130rem;
  }
}

.m-press-release-label {
  font-weight: bold;
  font-size: 1.6rem;
  margin-right: 1rem;
  align-self: flex-start;
  margin-bottom: 1rem;
}
@media screen and (min-width: 768px) {
  .m-press-release-label {
    margin-bottom: 0;
    align-self: auto;
  }
}

.m-press-search-text {
  flex-grow: 1;
  border: 0;
  background: #ebebeb;
  height: 3.2rem;
  padding: 0.5rem 1.5rem;
  font-size: 1.2rem;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .m-press-search-text {
    width: auto;
  }
}

.m-submit-button {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  width: 16.5rem;
  cursor: pointer;
  position: relative;
  background: #72392c;
  color: #ffffff;
  text-align: left;
  margin-left: 1rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  padding: 0 1rem;
  height: 3.2rem;
  line-height: 3.2rem;
  border: 0;
  transition: all 0.3s ease;
  align-self: flex-end;
  margin-top: 1rem;
}
@media screen and (min-width: 768px) {
  .m-submit-button {
    margin-top: 0;
    align-self: auto;
  }
}
.m-submit-button::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: url("../img/white-chevron.png") no-repeat;
  background-size: cover;
  width: 0.9rem;
  height: 1.6rem;
  right: 1rem;
}
.m-submit-button:hover, .m-submit-button.hover, .m-submit-button:focus {
  background: #da291e;
}

.m-press-release-table {
  position: relative;
  margin: 0 1.7rem;
  font-size: 1.6rem;
  line-height: 2.3rem;
  padding: 2rem 0 4rem;
  border: 0.1rem solid #aaaaaa;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .m-press-release-table {
    width: 95%;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-press-release-table {
    margin: 0 auto 0;
    width: 100%;
    max-width: 130rem;
  }
}

.m-press-release-header {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  font-size: 1.8rem;
  background: #72392c url(../img/pressreleasesorter.png) no-repeat right center;
  background-position-x: calc(100% - 1rem);
  color: #ffffff;
  padding: 0.5rem 0;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}
.m-press-release-header:first-child {
  background-image: none;
}
.m-press-release-header:not(:last-child) {
  border-right: 0.1rem solid #aaaaaa;
}
.m-press-release-header.ascending, .m-press-release-header.descending {
  background-color: #da291e;
}
.m-press-release-header.ascending {
  background-position-y: -12.1rem;
}
.m-press-release-header.descending {
  background-position-y: 1.9rem;
}

.m-press-release-link {
  color: #444546;
  transition: all 0.3s ease;
}
.m-press-release-link:hover, .m-press-release-link.hover, .m-press-release-link:focus {
  color: #da291e;
}

th,
td {
  vertical-align: top;
}
th.is-view,
td.is-view {
  width: 4.5rem;
}
th.is-date,
td.is-date {
  width: 11.5rem;
}

.m-press-release-td {
  background: #dfdbda;
  padding: 1rem;
  text-align: left;
  border-bottom: 0;
}
.m-press-release-td:not(:last-child) {
  border-right: 0.1rem solid #aaaaaa;
}
.m-press-release-td.is-date, .m-press-release-td.is-view {
  text-align: center;
}
.m-press-release-td.releasetitle {
  word-break: break-word;
}
.is-expanded .m-press-release-td, .m-press-release-td.is-sorted-by {
  background: #ffffff;
}

.m-view-button {
  cursor: pointer;
  display: block;
  width: 2.5rem;
  height: 2.3rem;
  background: url("../img/icon-arrows.png") no-repeat 0.8rem -1.8rem #ecac00;
  transition-delay: 800ms;
  text-indent: -9999.9rem;
  transition: background-color 0.3s ease;
}
.m-view-button:hover {
  background-color: #da291e;
}
.m-view-button.is-expanded {
  transform: rotate(180deg);
  background-color: #da291e;
}

.m-press-release-expandable {
  border-bottom: 0.1rem solid #aaaaaa;
  padding: 0;
}
.m-press-release-expandable .rich-text {
  padding-left: 7rem;
  padding-right: 2rem;
  background: #ffffff;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
.m-press-release-expandable .rich-text.is-expanded {
  max-height: 100rem;
  transition: max-height 1s ease-in-out;
  border-top: 0.1rem solid #aaaaaa;
}

.m-press-release-data {
  width: 100%;
}

.m-press-release-article {
  background: #ffffff;
  padding: 4rem;
}
.m-press-release-article h2 {
  font-size: 3rem;
}
.m-press-release-article h2:not(:first-of-type) {
  font-family: arial, helvetica, sans-serif;
  margin: 4rem 0 0;
}
.m-press-release-article h2 + h3 {
  margin-bottom: 0;
  font-weight: bold;
}
.m-press-release-article h3 {
  margin-top: 4rem;
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.6rem;
}
.m-press-release-article h3,
.m-press-release-article h4 {
  font-family: arial, helvetica, sans-serif;
  color: #444546;
}
.m-press-release-article h3,
.m-press-release-article p {
  margin-bottom: 2rem;
}
.m-press-release-article p {
  color: #444546;
}
.m-press-release-article h4 {
  margin-bottom: 0;
  font-weight: bold;
}
.m-press-release-article i {
  font-style: italic;
}

.m-press-release-downloads {
  margin-top: 4rem;
  padding: 4rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .m-press-release-downloads {
    flex-direction: row;
  }
}
.m-press-release-downloads > * {
  flex-basis: 49%;
}
.m-press-release-downloads > *:first-child {
  flex: 0 1 100%;
}
.m-press-release-downloads a {
  color: #47484a;
}

.m-press-release-btn {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  background: #ffffff;
  height: 4rem;
  line-height: 4rem;
  font-size: 1.6rem;
  color: #47484a;
  text-transform: uppercase;
  padding-left: 4rem;
  position: relative;
  margin-bottom: 2rem;
}
@media screen and (min-width: 768px) {
  .m-press-release-btn {
    margin-bottom: 0;
    max-width: 49%;
  }
}
.m-press-release-btn::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 1.9rem;
  height: 2.3rem;
  background-image: url("../img/icon-sprite.png");
  background-position: -0.2rem -30.1rem;
  background-repeat: no-repeat;
  left: 1rem;
}
.m-press-release-email {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  background: #ffffff;
  padding: 0.5rem 0.8rem;
  min-height: 3rem;
  position: relative;
  transition: 200ms min-height ease;
  z-index: 0;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-press-release-email.has-error {
    padding-bottom: 4rem;
  }
}
.m-press-release-email.has-error .m-press-release-button {
  top: 2rem;
}
.m-press-release-email.has-error .m-press-release-input {
  height: 3rem;
}
.m-press-release-email.has-error .m-press-release-input::placeholder {
  color: #ff0000;
}

.m-press-release-input {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  background: #f0f0f0;
  padding: 0.5rem 1rem;
  height: 3rem;
  border: 0;
  width: 85%;
  font-weight: bold;
  color: #000;
  font-size: 1.6rem;
}
@media screen and (min-width: 768px) {
  .m-press-release-input {
    width: 95%;
  }
}
.m-press-release-input::placeholder {
  font-weight: bold;
  color: #000;
}

.m-press-release-button {
  background-color: #ffffff;
  border: 0;
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-error-message {
    position: absolute;
    bottom: 0;
    left: 0.8rem;
    z-index: 100;
  }
}

.m-disclaimer {
  font-size: 1.4rem;
  color: #ff0000;
}

.m-press-release-article table {
  margin: 0 auto;
}
.m-press-release-article table th, .m-press-release-article table td {
  padding: 1rem;
}
@media screen and (max-width: 767px) {
  .m-press-release-article table th, .m-press-release-article table td {
    padding: 0.8rem;
    width: 25%;
  }
  .m-press-release-article table th:nth-child(2), .m-press-release-article table td:nth-child(2) {
    width: 50%;
  }
}
.m-press-release-article table th {
  border-bottom: 0.2rem solid #000000;
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .m-press-release-article table th {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 767px) {
  .m-press-release-article table td {
    font-size: 1.2rem;
  }
}

.m-table-subtitle {
  font-weight: bold;
  padding: 1rem 0;
}

.m-news-events {
  padding: 0;
  margin-bottom: 0;
}
.m-news-events .m-column {
  width: 100%;
  padding-left: 6rem;
  padding-top: 0.8rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-news-events .m-column {
    padding: 0;
    padding-left: 6rem;
    padding-top: 0.8rem;
    width: 47.5%;
  }
}
.m-news-events .m-column:first-child {
  margin-bottom: 6rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-news-events .m-column:first-child {
    margin-bottom: 0;
    margin-right: 5%;
  }
}

.m-search-form-wrapper,
.m-results-wrapper {
  padding: 2rem 2.7rem;
  background: #ffffff;
}
@media screen and (min-width: 768px) {
  .m-search-form-wrapper,
.m-results-wrapper {
    align-items: center;
  }
}

.m-search-form-wrapper {
  flex-direction: column;
  margin: 0;
  margin-bottom: 3.7rem;
}
@media screen and (min-width: 768px) {
  .m-search-form-wrapper {
    flex-direction: row;
  }
}

.m-search-form {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .m-search-form {
    width: auto;
    flex-direction: row;
  }
}

.m-search-input {
  font-size: 1.6rem;
  border: none;
  background: #ebebeb;
  flex-grow: 1;
  min-height: 4rem;
  margin-bottom: 1.5rem;
  text-indent: 1.8rem;
}
@media screen and (min-width: 768px) {
  .m-search-input {
    min-height: 3.2rem;
    font-size: 1.2rem;
    margin-right: 2rem;
    margin-bottom: 0;
  }
}
.m-search-input::placeholder {
  color: #000000;
}

.m-search-submit {
  max-width: 16.3rem;
}

.m-results-label,
.m-no-results,
.m-result-title,
.m-result-path {
  margin: 0;
}

.m-results-label,
.m-no-results,
.m-result-title {
  font-weight: 700;
}

.m-results-label {
  margin-bottom: 1rem;
}
@media screen and (min-width: 768px) {
  .m-results-label {
    margin-bottom: 0;
  }
}

.m-result-title {
  margin-bottom: 0.5rem;
  color: #444546;
}

.m-result-path {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  font-size: 1.4rem;
  color: #333435;
}

@media screen and (min-width: 768px) {
  .m-results-label {
    width: 20%;
  }
}

.m-result {
  color: inherit;
  border-top: 0.1rem solid #e2e5e7;
  display: block;
  margin-bottom: 2rem;
  padding-right: 1.5rem;
  padding-top: 2rem;
  background: url(../img/chevron-red.png) no-repeat right calc(50% + 1rem);
}
.m-result:first-child {
  border-top: none;
}
.m-result:hover {
  text-decoration: none;
}

.contact .l-section {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .contact .m-content {
    width: 100%;
    margin: 0;
    padding: 2rem;
  }
}
.contact .m-content p {
  margin: 0 0 0.5rem;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .contact .m-content {
    padding: 1rem;
  }
}
.contact .m-button-arrow {
  margin-top: 1rem;
}

.m-contact-layout {
  display: flex;
  flex-direction: column;
  padding: 3rem 0 0;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .m-contact-layout {
    padding: 0;
    margin-bottom: 0.8rem;
    flex-direction: row;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-contact-layout {
    max-width: 136.3rem;
    padding: 3rem 3rem 0.1rem 3rem;
  }
}

.m-contact-info {
  margin-bottom: 3rem;
}
@media screen and (max-width: 767px) {
  .m-contact-info:last-child {
    margin-bottom: 0;
  }
}

.m-contact-map {
  width: 100%;
  height: 25rem;
  border: 0.1rem solid #e1e1e1;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .m-contact-map {
    width: 60rem;
    height: 36rem;
    margin-top: 4rem;
  }
}

.m-leadership .m-content.show {
  display: block;
}
.m-leadership .m-content.hide {
  display: none;
}

.m-leadership-filters {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  padding: 4rem 0;
}
@media screen and (min-width: 768px) {
  .m-leadership-filters {
    flex-direction: row;
    align-items: center;
  }
}
.m-leadership-filters p {
  margin: 0 0 1.5rem 0;
}
@media screen and (min-width: 768px) {
  .m-leadership-filters p {
    margin: 0 1.5rem 0 0;
  }
}
.m-leadership-filters .m-leadership-filter {
  text-align: left;
  margin-bottom: 1.5rem;
  min-width: 21.5rem;
  padding: 0.7rem 3rem 0.7rem 1.6rem;
}
@media screen and (min-width: 768px) {
  .m-leadership-filters .m-leadership-filter {
    margin: 0 1.5rem 0 0;
  }
}
.m-leadership-filters .m-leadership-filter.active {
  background-color: #4f291f;
}

.m-leadership-filter {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  text-transform: uppercase;
}

.m-leadership-title {
  width: 100%;
}

.m-leadership-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .m-leadership-cards {
    flex-direction: row;
  }
}

.m-leadership-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 768px) {
  .m-leadership-card {
    width: calc(50% - 1rem);
  }
  .m-leadership-card:nth-of-type(odd) {
    margin-right: 2rem;
  }
  .m-leadership-card:last-child {
    margin-right: auto;
  }
}
.m-leadership-card .rich-text p {
  margin: 3rem 0 0 0;
}

.m-leadership-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.m-leadership-info p {
  font-family: "Conduit ITC W01 Medium", Arial, sans-serif;
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}
.m-leadership-info p:first-of-type {
  margin-top: 3rem;
}

.m-leadership_name {
  font-size: 2.6rem;
}

.m-leadership_job {
  font-size: 2rem;
}
.m-modal .m-leadership_job {
  width: 90%;
}

.m-leadership-description {
  max-width: 50rem;
}

.m-leadership-modal-btn {
  background-color: transparent;
  border: none;
  border-radius: none;
  color: #444546;
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-top: 3rem;
}
.m-leadership-modal-btn:hover {
  cursor: pointer;
}

.job-postings .m-content {
  display: flex;
  justify-content: center;
  width: calc(100% - 3.4rem);
}
@media screen and (min-width: 768px) {
  .job-postings .m-content {
    width: 100%;
  }
}

.m-job-postings {
  margin: 1rem auto 4rem;
  height: 85rem;
}
@media screen and (min-width: 768px) {
  .m-job-postings {
    width: 100% !important;
    height: 65rem;
    display: block;
  }
}

.terms ol {
  margin-left: 1.5rem;
  padding-left: 2rem;
}
.terms ol li {
  padding-bottom: 0.5rem;
}
.terms ol ol {
  list-style-type: lower-latin;
}