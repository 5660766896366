.m-breadcrumb-wrapper {
  display: inline-block;
	background: $white;
	position: relative;
  z-index: 1;
  padding: 2.7rem 0 1.5rem;

  .home &{
    display: none;
    margin: 0 auto;
  }

  .terms & {
    visibility: hidden;
  }


  .pipeline & {
    padding-bottom: 0;
  }
}

.m-breadcrumbs {
  overflow: auto;
  display: flex;

  @include desktop {
    padding-left: 0.4rem;
  }
}

.m-breadcrumbs-item {
  @include conduit-itc-medium;
	font-size: 1.4rem;
	color: $body-copy;
  text-transform: uppercase;
  text-decoration: none;
  display: block;

  &:not(:first-child):before {
    content: "> ";
    color: $brand-colour-1;
    padding-left: 0.3rem;
  }
}

.m-breadcrumbs-link {
  @include conduit-itc-medium;
	font-size: 1.4rem;
	color: $body-copy;
  text-transform: uppercase;
  text-decoration: none;

  &:hover, &:focus, &.hover {
    text-decoration: underline;
  }
}