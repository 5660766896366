.m-fifty_fifty {
    &:last-of-type {
        margin-bottom: 0;
    }

    .m-column-1 {
        margin-bottom: 1rem;
        @include tablet {
            margin-bottom: 0;
        }
    }

    .m-two_columns {
        flex-wrap: wrap;
        padding: $gutter 0;
        img {
            max-width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
        }
    
        
    
        h3 {
            margin-top: 2rem;
            margin-bottom: 0;
            &:first-child {
                margin-top: 0;
            }

            & + p {
                margin: 0;
            }
        }
        p {
            margin: 2rem 0;
            &:first-child {
                margin-top: 0;
            }
            & + h3 {
                margin: 0;
            }
        }


    
       
    
        @include tablet {
            .m-column {
                width: calc(50% - 2.5rem);
                
            }
        }
    }
    
}


